<template>
    <b-col md="6" lg="3" xl="3" :class="['col-button-card', disabled && 'disabled', finished && 'finalizada']" class="mt-2">
        <b-button class="btn btn-operator" @click="$emit('click')">
            <div
                :class="[
                    'card-action',
                    load && variant,
                    finished && 'finalizada',
                ]"
            >
                <div
                    :class="['color-bar-card', variant]"
                    v-if="!load && !finished"
                ></div>
                <div v-if="finished" class="card-finish">
                    <span>{{$t('ProductionProgress.Finished')}}</span>
                    <p>{{ timeEvent }}</p>
                </div>
                <div class="infos-card" v-else>
                    <div class="header-card">
                        <h1
                            :class="[
                                'title-card',
                                variant,
                                load && 'white-title',
                            ]"
                        >
                            {{ title }}
                        </h1>
                        <div class="data-hora-apontamento" v-if="load">
                            <span>
                                {{$t('ProductionProgress.Last')}}
                            </span>
                            <p class="data-hora">
                                {{ timeEvent }}
                            </p>
                        </div>
                    </div>

                    <div class="load" v-if="load">
                        <imgIconLoad />
                        <p class="temporizador">{{ timer }} hrs</p>
                    </div>
                    <div class="adicionar" v-if="adicionar">
                        <imgIconAdd />
                        <span>
                            {{$t('ProductionProgress.Add')}}
                        </span>
                    </div>
                    <div class="action" v-if="!adicionar">
                        <p class="description-action" v-if="!timeEvent">
                            {{action}}
                        </p>
                        <div
                            class="data-hora-apontamento"
                            v-if="timeEvent && !load"
                        >
                            <span>
                                {{$t('ProductionProgress.Last')}}
                            </span>
                            <p class="data-hora">
                                {{ timeEvent}}
                            </p>
                        </div>
                        <div class="icon-card" v-if="!load">
                            <imgIconDark v-if="variant == 'dark'" />
                            <imgIconDanger v-else-if="variant == 'danger'" />
                            <imgIconSuccess v-else-if="variant == 'success'" />
                            <imgIconWarning v-else />
                        </div>
                    </div>
                </div>
            </div>
        </b-button>
    </b-col>
</template>

<script>
import { BButton, BTableSimple, BCol, BTbody, BImg } from "bootstrap-vue";
import imgIconDark from "@/assets/images/pages/actionSetup.svg";
import imgIconDanger from "@/assets/images/pages/actionPausar.svg";
import imgIconSuccess from "@/assets/images/pages/actionFinalizar.svg";
import imgIconWarning from "@/assets/images/pages/actionContinuar.svg";
import imgIconLoad from "@/assets/images/pages/load-icon.svg";
import imgIconAdd from "@/assets/images/pages/add-icon.svg";
import moment from "moment";

export default {
    components: {
        BCol,
        BButton,
        BTableSimple,
        BTbody,
        BImg,
        imgIconDark,
        imgIconDanger,
        imgIconSuccess,
        imgIconWarning,
        imgIconLoad,
        imgIconAdd,
        moment
    },
    props: {
        variant: {
            type: String,
            default: "",
        },
        action: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        time: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        load: {
            type: Boolean,
            default: false,
        },
        adicionar: {
            type: Boolean,
            default: false,
        },
        timer: {
            type: String,
            default: "",
        },
        finished: {
            type: Boolean,
            default: false,
        },
        timeEvent: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
};
</script>
<style lang="scss">
.col-button-card {
    &.disabled {
        opacity: 0.5;
        pointer-events: none !important;
        &.finalizada{
            opacity: 1 !important;
        }
    }
    .btn-operator {
        height: 100%;
        width: 100%;
        background-color: #ffff !important;
        border: none;
        overflow: hidden;
        padding: 0 !important;
        box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
        border-radius: 6px;
        position: relative;
        margin: 0 !important;

        .card-action {
            height: 100%;
            display: flex;
            &.warning {
                background-color: #ff9800;
            }
            &.danger {
                background-color: #f44336;
            }
            &.success {
                background-color: #43a047;
            }
            &.dark {
                background-color: #607d8b;
            }
            &.finalizada {
                background-color: #43a047;
                justify-content: center;
                align-items: center;
                padding: 38px 69px;
            }

            .infos-card {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: space-between;
                .header-card {
                    display: flex;
                    justify-content: space-between;
                    .title-card {
                        margin-left: 17px;
                        margin-top: 16px;
                        margin-bottom: 23px;
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 38px;
                        &.warning {
                            color: #ff9800;
                        }
                        &.danger {
                            color: #f44336;
                        }
                        &.success {
                            color: #43a047;
                        }
                        &.dark {
                            color: #607d8b;
                        }
                        &.white-title {
                            color: #ffff;
                        }
                    }
                    .data-hora-apontamento {
                        display: flex;
                        flex-direction: column;
                        text-align: end;
                        justify-content: center;
                        margin-right: 15px;
                        margin: 0;
                    }
                }

                .action {
                    display: flex;
                    margin-left: 17px;
                    margin-bottom: 16px;
                    justify-content: space-between;
                    .description-action {
                        color: #4c4541;
                        margin-bottom: 0;
                        display: flex;
                        align-items: end;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .icon-card {
                        margin-right: 17px;
                    }
                    .data-hora-apontamento {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin: 0;
                        span {
                            color: #998f8a;
                        }
                        p {
                            color: #4c4541;
                            margin: 0;
                        }
                    }
                }
                .load {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg{
                        animation: load 2s linear infinite;
                        margin: auto 0 !important;
                    }
                    @keyframes load {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                    .temporizador {
                        font-weight: 400;
                        font-size: 28px;
                        line-height: 38px;
                        color: #ffff;
                        margin-left: 7px;
                        margin-bottom: 0;
                    }
                }
                .adicionar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #ffff;
                    height: 32px;
                    span {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 20px;
                        color: #974900;
                        margin-left: 6px;
                    }
                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            .color-bar-card {
                position: absolute;
                height: 100%;
                width: 9px;

                &.warning {
                    background-color: #ff9800;
                }
                &.danger {
                    background-color: #f44336;
                }
                &.success {
                    background-color: #43a047;
                }
                &.dark {
                    background-color: #607d8b;
                }
            }
            .card-finish {
                color: #fff;
                font-weight: 600;
                display: flex;
                flex-direction: column;

                span {
                    font-size: 28px;
                    line-height: 38px;
                    text-align: center;
                    margin: 0;
                }
                p {
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .btn-operator {
        .card-action {
            &.finalizada {
                padding: 37.5px 95px !important;

                .card-finish {
                    span {
                        line-height: 26px !important;
                        font-size: 18px !important;
                    }

                    p {
                        line-height: 16px !important;
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}
</style>
