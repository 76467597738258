<template>
    <div class="container-prod-progress">
        <div>
            <ModalDeleteApontamento
                :deleteApontamento="deleteProductionProgress"
                :apontamentoId="apontamentoId"
            />
            <!-- modal Setup-->
            <b-modal
                ref="formModal"
                id="modal-setup"
                :title="$t('OperationProgress.Setup')"
                modal-class="modal-dark"
                hide-footer
                centered
            >
                <div class="text-center">
                    <b-form>
                        <b-row>
                            <b-col
                                md="12"
                                class="card-error"
                                v-if="
                                    isControlByHour
                                        ? order.last_status_by_user ==
                                              'Executando' ||
                                          order.last_status_by_user ==
                                              'Iniciado'
                                        : order.last_status == 'Executando' ||
                                          order.last_status == 'Iniciado'
                                "
                            >
                                <p class="text-error">
                                    {{$t('ProductionProgress.AlertNewSetup')}}
                                </p>
                            </b-col>
                            <b-col md="12">
                                <FormSelect
                                    :label="$t('OperationProgress.Resource')"
                                    class="form-item"
                                    :value="modalSetup.resource_id"
                                    :Options="insertOptionDefaultInSelect(resourcesList)"
                                    :disabled="!allowChangeResource  && !infoMultipleOrders"
                                    :msgError="
                                        validation.modalSetup_resource_id
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalSetup_resource_id
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalSetup.resource_id',
                                                value
                                            )
                                    "
                                />
                            </b-col>

                            <b-col md="6" class="col-input-icon">
                                <FormPicker
                                    :label="$t('OperationProgress.Date')"
                                    :value="modalSetup.operation_date"
                                    class="form-date"
                                    :disabled="!requestDateTime"
                                    :msgError="
                                        validation.modalSetup_operation_date
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalSetup_operation_date
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalSetup.operation_date',
                                                value
                                            )
                                    "
                                    type="data"
                                />
                            </b-col>

                            <b-col md="6" class="col-input-icon">
                                <FormPicker
                                    :label="$t('ProductionProgress.Hour')"
                                    :value="modalSetup.operation_time"
                                    class="form-date"
                                    :disabled="!requestDateTime"
                                    :msgError="
                                        validation.modalSetup_operation_time
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalSetup_operation_time
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalSetup.operation_time',
                                                value
                                            )
                                    "
                                    :type="'hora'"
                                />
                            </b-col>

                            <b-col md="12" class="col-button">
                                <b-form-group>
                                    <b-button
                                        v-if="!sendingForm"
                                        variant="dark"
                                        class="btn-color"
                                        :disabled="disableButtonModal"
                                        @click="
                                            onsubmit(
                                                statusSetup.value,
                                                'modalSetup',
                                                modalSetup.operation_date,
                                                modalSetup.operation_time,
                                                null,
                                                null,
                                                null,
                                                null,
                                                modalSetup.resource_id
                                            )
                                        "
                                    >
                                        {{$t('ProductionProgress.Save')}}
                                    </b-button>

                                    <b-button
                                        v-if="sendingForm"
                                        variant="dark"
                                        disabled
                                        class="btn-color"
                                    >
                                        <b-spinner small />
                                        Loading ...
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-modal>

            <!-- modal Running-->
            <b-modal
                ref="formModal"
                id="modal-running"
                :title="
                    cardIniciarParcialContinuarTitle() == $t('ProductionProgress.Partial')
                        ? $t('ProductionProgress.PartialOperationProgress')
                        : cardIniciarParcialContinuarTitle()
                "
                modal-class="modal-warning"
                hide-footer
                centered
            >
                <div class="text-center">
                    <b-form>
                        <b-row>
                            <b-col md="12"
                                class="alert-fields form-item"
                                v-if="showMessageErrorFields && !isControlByHour && !infoMultipleOrders"
                            >
                                <span>
                                    {{  $t('ProductionProgress.MessageValidationFields') }}
                                </span>
                            </b-col>
                            <b-col
                                md="4"
                                v-if="
                                    !isControlByHour &&
                                    cardIniciarParcialContinuarTitle() ==
                                        $t('ProductionProgress.Partial')
                                "
                            >
                                <FormInput
                                    min="0"
                                    step=".01"
                                    oninput="validity.valid||(value='');"
                                    type="number"
                                    placeholder="0"
                                    :label="$t('ProductionProgress.Produced1')"
                                    class="form-item"
                                    :value="modalRunning.produzido"
                                    :msgError="
                                        validation.modalRunning_produzido
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalRunning_produzido
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalRunning.produzido',
                                                value
                                            )
                                    "
                                    :disabled="infoMultipleOrders"
                                />
                            </b-col>
                            <b-col
                                md="4"
                                v-if="
                                    !isControlByHour &&
                                    cardIniciarParcialContinuarTitle() ==
                                        $t('ProductionProgress.Partial')
                                "
                            >
                                <FormInput
                                    min="0"
                                    step=".01"
                                    oninput="validity.valid||(value='');"
                                    type="number"
                                    placeholder="0"
                                    :label="$t('ProductionProgress.Rejection')"
                                    class="form-item"
                                    :value="modalRunning.refugo"
                                    :msgError="
                                        validation.modalRunning_refugo.msgError
                                    "
                                    :isError="
                                        validation.modalRunning_refugo.isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalRunning.refugo',
                                                value
                                            )
                                    "
                                    :disabled="infoMultipleOrders"
                                />
                            </b-col>
                            <b-col
                                md="4"
                                v-if="
                                    !isControlByHour &&
                                    cardIniciarParcialContinuarTitle() ==
                                        $t('ProductionProgress.Partial')
                                "
                            >
                                <FormSelect
                                    :label="$t('ProductionProgress.ReasonsForRejection')"
                                    class="form-item"
                                    :value="modalRunning.motivo_refugo"
                                    :msgError="
                                        validation.modalRunning_motivo_refugo
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalRunning_motivo_refugo
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalRunning.motivo_refugo',
                                                value
                                            )
                                    "
                                    :Options="insertOptionDefaultInSelect(refuseStatus)"
                                    :disabled="infoMultipleOrders"
                                />
                            </b-col>
                            <b-col
                                md="6"
                                v-if="
                                    !isControlByHour &&
                                    cardIniciarParcialContinuarTitle() ==
                                        $t('ProductionProgress.Partial')
                                "
                                class="col-input-form"
                            >
                                <FormInput
                                    min="0"
                                    step=".01"
                                    oninput="validity.valid||(value='');"
                                    type="number"
                                    placeholder="0"
                                    :label="$t('ProductionProgress.Rework')"
                                    class="form-item"
                                    :value="modalRunning.retrabalho"
                                    :msgError="
                                        validation.modalRunning_retrabalho
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalRunning_retrabalho
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalRunning.retrabalho',
                                                value
                                            )
                                    "
                                    :disabled="infoMultipleOrders"
                                />
                            </b-col>
                            <b-col
                                :md="
                                    !isControlByHour &&
                                    cardIniciarParcialContinuarTitle() ==
                                        $t('ProductionProgress.Partial')
                                        ? '6'
                                        : '12'
                                "
                                class="col-input-form"
                            >
                                <FormSelect
                                    :label="$t('OperationProgress.Resource')"
                                    class="form-item"
                                    :value="modalRunning.resource_id"
                                    :Options="insertOptionDefaultInSelect(resourcesList)"
                                    :disabled="!allowChangeResource  && !infoMultipleOrders"
                                    :msgError="
                                        validation.modalRunning_resource_id
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalRunning_resource_id
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalRunning.resource_id',
                                                value
                                            )
                                    "
                                />
                            </b-col>

                            <b-col md="6" class="col-input-icon">
                                <FormPicker
                                    :label="$t('OperationProgress.Date')"
                                    :value="modalRunning.operation_date"
                                    class="form-date"
                                    :disabled="!requestDateTime"
                                    :msgError="
                                        validation.modalRunning_operation_date
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalRunning_operation_date
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalRunning.operation_date',
                                                value
                                            )
                                    "
                                    type="data"
                                />
                            </b-col>

                            <b-col md="6" class="col-input-icon">
                                <FormPicker
                                    :label="$t('ProductionProgress.Hour')"
                                    :value="modalRunning.operation_time"
                                    class="form-date"
                                    :disabled="!requestDateTime"
                                    :msgError="
                                        validation.modalRunning_operation_time
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalRunning_operation_time
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalRunning.operation_time',
                                                value
                                            )
                                    "
                                    :type="'hora'"
                                />
                            </b-col>

                            <b-col md="12" class="col-button">
                                <b-form-group>
                                    <b-button
                                        v-if="!sendingForm"
                                        variant="dark"
                                        class="btn-color"
                                        :disabled="disableButtonModal"
                                        @click="
                                            onsubmit(
                                                isControlByHour
                                                    ? order.last_started_by_user
                                                        ? statusRunning.value
                                                        : statusStart.value
                                                    : order.last_started
                                                    ? statusRunning.value
                                                    : statusStart.value,
                                                'modalRunning',
                                                modalRunning.operation_date,
                                                modalRunning.operation_time,
                                                modalRunning.produzido,
                                                modalRunning.refugo,
                                                modalRunning.retrabalho,
                                                modalRunning.motivo_refugo,
                                                modalRunning.resource_id
                                            )
                                        "
                                    >
                                        {{$t('ProductionProgress.Save')}}
                                    </b-button>

                                    <b-button
                                        v-if="sendingForm"
                                        variant="dark"
                                        disabled
                                        class="btn-color"
                                    >
                                        <b-spinner small />
                                        Loading ...
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-modal>

            <!-- modal Stop-->
            <b-modal
                ref="formModal"
                id="modal-stop"
                :title="$t('ProductionProgress.Pause')"
                modal-class="modal-danger"
                hide-footer
                centered
            >
            <div class="text-center">
                <b-form>
                    <b-row>
                            <b-col
                                md="6"
                                v-if="!isControlByHour"
                                class="col-select"
                            >
                                <FormSelect
                                    label="Status"
                                    class="form-item"
                                    :value="modalStop.status_id"
                                    :options="insertOptionDefaultInSelect(statusOptions)"
                                    :msgError="
                                        validation.modalStop_status_id.msgError
                                    "
                                    :isError="
                                        validation.modalStop_status_id.isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalStop.status_id',
                                                value
                                            )
                                    "
                                    :style="modalStop.status_id == '' ? 'color: #CFC4BE !important;' : ''"
                                />
                            </b-col>
                            <b-col
                                :md="!isControlByHour ? '6' : '12'"
                                class="col-select"
                            >
                                <FormSelect
                                    :label="$t('OperationProgress.Resource')"
                                    class="form-item"
                                    :value="modalStop.resource_id"
                                    :Options="insertOptionDefaultInSelect(resourcesList)"
                                    :disabled="!allowChangeResource  && !infoMultipleOrders"
                                    :msgError="
                                        validation.modalStop_resource_id
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalStop_resource_id.isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalStop.resource_id',
                                                value
                                            )
                                    "
                                />
                            </b-col>

                            <b-col md="6" class="col-input-icon">
                                <FormPicker
                                    :label="$t('OperationProgress.Date')"
                                    :value="modalStop.operation_date"
                                    class="form-date"
                                    :disabled="!requestDateTime"
                                    :msgError="
                                        validation.modalStop_operation_date
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalStop_operation_date
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalStop.operation_date',
                                                value
                                            )
                                    "
                                    type="data"
                                />
                            </b-col>

                            <b-col md="6" class="col-input-icon">
                                <FormPicker
                                    :label="$t('ProductionProgress.Hour')"
                                    :value="modalStop.operation_time"
                                    class="form-date"
                                    :disabled="!requestDateTime"
                                    :msgError="
                                        validation.modalStop_operation_time
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalStop_operation_time
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalStop.operation_time',
                                                value
                                            )
                                    "
                                    :type="'hora'"
                                />
                            </b-col>
                            <b-col
                                md="12"
                                class="col-select"
                                v-if="isControlByHour"
                            >
                                <FormSelect
                                    label="Status"
                                    class="form-item"
                                    :value="modalStop.status_id"
                                    :options="insertOptionDefaultInSelect(statusOptions)"
                                    :msgError="
                                        validation.modalStop_status_id.msgError
                                    "
                                    :isError="
                                        validation.modalStop_status_id.isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalStop.status_id',
                                                value
                                            )
                                    "
                                    :style="modalStop.status_id == '' ? 'color: #CFC4BE !important;' : ''"
                                />
                            </b-col>

                            <b-col md="12" class="col-button">
                                <b-form-group>
                                    <b-button
                                        v-if="!sendingForm"
                                        variant="dark"
                                        class="btn-color"
                                        :disabled="disableButtonModal"
                                        @click="
                                            onsubmit(
                                                modalStop.status_id,
                                                'modalStop',
                                                modalStop.operation_date,
                                                modalStop.operation_time,
                                                null,
                                                null,
                                                null,
                                                null,
                                                modalStop.resource_id
                                            )
                                        "
                                    >
                                        {{$t('ProductionProgress.Save')}}
                                    </b-button>

                                    <b-button
                                        v-if="sendingForm"
                                        variant="dark"
                                        disabled
                                        class="btn-color"
                                    >
                                        <b-spinner small />
                                        Loading ...
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-modal>

            <!-- modal FInish-->
            <b-modal
                ref="formModal"
                id="modal-finish"
                :title="$t('ProductionProgress.Finish')"
                modal-class="modal-success"
                hide-footer
                centered
            >
                <div class="text-center">
                    <b-form>
                        <b-row>
                            <b-col
                                md="12"
                                class="col-input-form"
                            >
                                <FormSelect
                                    :label="$t('OperationProgress.Resource')"
                                    class="form-item"
                                    :value="modalFinish.resource_id"
                                    :Options="insertOptionDefaultInSelect(resourcesList)"
                                    :disabled="!allowChangeResource  && !infoMultipleOrders"
                                    :msgError="
                                        validation.modalFinish_resource_id
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalFinish_resource_id
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalFinish.resource_id',
                                                value
                                            )
                                    "
                                />
                            </b-col>
                            <b-col md="6" class="col-input-icon">
                                <FormPicker
                                    :label="$t('OperationProgress.Date')"
                                    :value="modalFinish.operation_date"
                                    class="form-date"
                                    :disabled="!requestDateTime"
                                    :msgError="
                                        validation.modalFinish_operation_date
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalFinish_operation_date
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalFinish.operation_date',
                                                value
                                            )
                                    "
                                    type="data"
                                />
                            </b-col>

                            <b-col md="6" class="col-input-icon">
                                <FormPicker
                                    :label="$t('ProductionProgress.Hour')"
                                    :value="modalFinish.operation_time"
                                    class="form-date"
                                    :disabled="!requestDateTime"
                                    :msgError="
                                        validation.modalFinish_operation_time
                                            .msgError
                                    "
                                    :isError="
                                        validation.modalFinish_operation_time
                                            .isError
                                    "
                                    @input="
                                        (value) =>
                                            updateValue(
                                                'modalFinish.operation_time',
                                                value
                                            )
                                    "
                                    :type="'hora'"
                                />
                            </b-col>

                            <b-col md="12" class="col-button">
                                <b-form-group>
                                    <b-button
                                        v-if="!sendingForm"
                                        variant="dark"
                                        class="btn-color"
                                        :disabled="disableButtonModal"
                                        @click="
                                            onsubmit(
                                                statusFinish.value,
                                                'modalFinish',
                                                modalFinish.operation_date,
                                                modalFinish.operation_time,
                                                null,
                                                null,
                                                null,
                                                null,
                                                modalFinish.resource_id
                                            )
                                        "
                                    >
                                        {{$t('ProductionProgress.Save')}}
                                    </b-button>

                                    <b-button
                                        v-if="sendingForm"
                                        variant="dark"
                                        disabled
                                        class="btn-color"
                                    >
                                        <b-spinner small />
                                        Loading ...
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-modal>

            <Breadcrumb />
            <br />
            <b-row class="row-name-order">
                <b-col
                    md="12"
                    :style="`border: 1px solid ${
                        isControlByHour
                            ? order.last_color_status_by_user
                            : order.last_color_status
                                ? order.last_color_status
                                : order.status_color
                    }`"
                    class="col-name-order"
                >
                    <h1
                        class="order-name"
                        v-if="!infoMultipleOrders">
                        {{$t('ProductionProgress.Order')}}: {{ order.order_no }}
                    </h1>
                    <h1
                        class="order-name"
                        :class="infoMultipleOrders ? 'pb-0' : ''"
                        v-if="infoMultipleOrders"
                    >
                        {{$t('ProductionProgress.MassOperationProgress')}}
                    </h1>
                    <div
                        class="container-apontamentos d-flex justify-content-center aling-items-center"
                        v-if="infoMultipleOrders"
                    >
                        <div class="container-apontamentos-helper d-flex overflow-auto">
                            <div
                                class="card-apontamento"
                                v-for="(label, index) in labelSelectedOrders"
                                :key="`apontamento-${index}`"
                            >
                                <span>
                                    {{label}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <h2
                        class="order-status"
                        :style="`background-color:${
                            isControlByHour
                                ? order.last_color_status_by_user
                                : order.last_color_status ? order.last_color_status : order.status_color
                        }`"
                    >
                        Status: {{isControlByHour ? order.last_status_by_user : order.last_status }}
                    </h2>
                </b-col>
            </b-row>

            <b-row class="row-cards">

                <card-actions
                    variant="dark"
                    :action="$t('ProductionProgress.ClickToSetup')"
                    title="Setup"
                    :timer="timer"
                    :timeEvent="
                        isControlByHour
                            ? formataUltimoApontamento(order.last_setup_by_user)
                            : formataUltimoApontamento(order.last_setup)
                    "
                    :load="
                        isControlByHour
                            ? order.last_status_by_user == 'Setup'
                            : order.last_status == 'Setup'
                    "
                    v-b-modal.modal-setup
                    @click="checkModalDataAndEnableSaveButton('setup')"
                    :disabled="
                        isControlByHour
                            ? order.last_completed_by_user
                            : order.last_completed
                    "
                />
                <card-actions
                    variant="warning"
                    :action="$t('ProductionProgress.ClickToStart')"
                    :title="cardIniciarParcialContinuarTitle()"
                    :timer="timer"
                    v-b-modal.modal-running
                    :load="
                        isControlByHour
                            ? order.last_status_by_user == 'Executando' ||
                              order.last_status_by_user == 'Iniciado'
                            : order.last_status == 'Executando' ||
                              order.last_status == 'Iniciado'
                    "
                    :adicionar="
                        cardIniciarParcialContinuarTitle() == $t('ProductionProgress.Partial') &&
                        order.last_status != 'Operação Finalizada'
                    "
                    :timeEvent="
                        isControlByHour
                            ? (order.last_running_by_user
                                ?
                                formataUltimoApontamento(order.last_running_by_user)

                                :
                                formataUltimoApontamento(order.last_started_by_user)
                            )
                            : (order.last_running
                            ? formataUltimoApontamento(order.last_running)
                            : formataUltimoApontamento(order.last_started))
                    "
                    @click="checkModalDataAndEnableSaveButton('start')"
                    :disabled="
                        isControlByHour
                            ? order.last_completed_by_user
                            : order.last_completed
                    "
                />
                <card-actions
                    variant="danger"
                    :action="$t('ProductionProgress.ClickToPause')"
                    :title="$t('ProductionProgress.Pause')"
                    :timer="timer"
                    :adicionar="
                        cardIniciarParcialContinuarTitle() == $t('ProductionProgress.Continue')
                    "
                    v-b-modal.modal-stop
                    :load="
                        isControlByHour
                            ? ![  'Não Iniciado',
                                  'Setup',
                                  'Iniciado',
                                  'Started',
                                  'Running',
                                  'Executando',
                                  'Completed',
                                  'Operação Finalizada',
                              ].includes(order.last_status_by_user) &&
                              order.last_stopped_by_user
                            : ![  'Não Iniciado',
                                  'Setup',
                                  'Iniciado',
                                  'Started',
                                  'Running',
                                  'Executando',
                                  'Completed',
                                  'Operação Finalizada',
                              ].includes(order.last_status) &&
                              order.last_stopped
                    "
                    :timeEvent="
                        isControlByHour
                            ? formataUltimoApontamento(
                                  order.last_stopped_by_user
                              )
                            : formataUltimoApontamento(order.last_stopped)
                    "
                    @click="checkModalDataAndEnableSaveButton('stop')"
                    :disabled=" isControlByHour ? order.last_completed_by_user :  order.last_completed "
                />
                <card-actions
                    variant="success"
                    :action="$t('ProductionProgress.ClickToFinish')"
                    :title="$t('ProductionProgress.Finish')"
                    :timer="timer"
                    v-b-modal.modal-finish
                    :timeEvent="
                        isControlByHour
                            ? formataUltimoApontamento(
                                  order.last_completed_by_user
                              )
                            : formataUltimoApontamento(order.last_completed)
                    "
                    :finished="
                        isControlByHour
                            ? order.last_status_by_user == 'Operação Finalizada'
                            : order.last_status == 'Operação Finalizada'
                    "
                    @click="checkModalDataAndEnableSaveButton('finish')"
                    :disabled="
                        isControlByHour
                        ? order.last_status_by_user != 'Executando' && order.last_status_by_user != 'Iniciado'
                        : order.last_status != 'Executando' && order.last_status != 'Iniciado'
                    "
                />
            </b-row>

            <b-row class="col-progress" v-if="isControlByHour">
                <b-col md="12" class="mobile">
                    <b-card class="card-chart">
                        <div class="title-icon">
                            <p class="title-chart">{{ $t('Progress') }}</p>
                            <div v-if="forseenGraph">
                                <imgTooltipIcon
                                    id="tooltip-progresso-realizado-mobile"
                                />

                                <b-tooltip
                                    custom-class="tooltip-production-progress-op"
                                    target="tooltip-progresso-realizado-mobile"
                                    triggers="hover"
                                    positioning="bottom"
                                    placement="bottom"
                                >
                                    <div class="title-tooltip-production-progress-op">
                                        {{ $t('OperationProgress.ForecastProgress') }}
                                    </div>
                                    <div class="info-tooltip-production-progress-op">
                                        {{ $t('OperationProgress.ForecastProgressTooltip') }}
                                    </div>
                                </b-tooltip>
                            </div>
                            <div v-else>
                                <imgTooltipIcon
                                    id="tooltip-progresso-produtivo-mobile"
                                />
                                <b-tooltip
                                    custom-class="tooltip-production-progress-op"
                                    target="tooltip-progresso-produtivo-mobile"
                                    triggers="hover"
                                    positioning="bottom"
                                    placement="bottom"
                                >
                                    <div class="title-tooltip-production-progress-op">
                                        {{ $t('OperationProgress.RealProgress') }}
                                    </div>
                                    <div class="info-tooltip-production-progress-op">
                                        {{ $t('OperationProgress.RealProgressTooltip') }}
                                    </div>
                                </b-tooltip>
                            </div>
                        </div>
                        <div class="graph-buttons-mobile">
                            <button
                                :class="[
                                    'btn-graph-mobile',
                                    forseenGraph
                                        ? 'btn-active'
                                        : 'btn-disabled',
                                ]"
                                @click="forseenGraph = true"
                                type="button"
                            >
                            {{ $t('ProductionProgress.Forecast') }}
                            </button>
                            <button
                                :class="[
                                    'btn-graph-mobile',
                                    forseenGraph
                                        ? 'btn-disabled'
                                        : 'btn-active',
                                ]"
                                @click="forseenGraph = false"
                                type="button"
                            >
                                Real
                            </button>
                        </div>
                        <b-row>
                            <b-col md="12">
                                <div class="mobile-graph-area" v-if="forseenGraph">
                                    <generate-chart
                                        v-if="renderCharts"
                                        id="dc-pie-chart-progress"
                                        :order="order"
                                        type="1"
                                        :listProgress="percentagesListCompleted"
                                    />
                                    <div class="col-indicador">
                                        <p class="indicador-chart">Total</p>
                                        <p class="value-indicador-chart">
                                            {{ percentCompleted }}
                                        </p>
                                    </div>

                                    <div class="legenda">
                                        <div
                                            class="item-legenda legenda-realizado"
                                        >
                                            <div class="circle"></div>
                                            <p class="dados-legenda">
                                                {{$t('OperationProgress.Completed')}}
                                            </p>
                                        </div>
                                        <div
                                            class="item-legenda legenda-excedente"
                                            v-if="
                                                parseFloat(
                                                    order.time_consumed
                                                ) > 1
                                            "
                                        >
                                            <div class="circle"></div>
                                            <p class="dados-legenda">
                                                {{$t('OperationProgress.OverTime')}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="mobile-graph-area" v-else>
                                    <generate-chart
                                        v-if="renderCharts"
                                        id="dc-pie-chart-productive"
                                        :order="order"
                                        type="1"
                                        :listProgress="percentagesListProductive"
                                    />
                                    <div class="col-indicador">
                                        <p class="indicador-chart">Total</p>
                                        <p class="value-indicador-chart">
                                            {{ percentProductive }}
                                        </p>
                                    </div>

                                    <div class="legenda">
                                        <div
                                            class="item-legenda legenda-produtivo"
                                        >
                                            <div class="circle"></div>
                                            <p class="dados-legenda">
                                                {{$t('OperationProgress.Productive')}}
                                            </p>
                                        </div>
                                        <div
                                            class="item-legenda legenda-excedente"
                                            v-if="
                                                parseFloat(
                                                    order.completed_progress
                                                ) > 1
                                            "
                                        >
                                            <div class="circle"></div>
                                            <p class="dados-legenda">
                                                {{$t('OperationProgress.OverTime')}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col cols="6" lg="3" xl="3" class="web">
                    <b-card class="card-chart">
                        <div class="title-icon">
                            <p class="title-chart">{{ $t('OperationProgress.ForecastProgress') }}</p>
                            <imgTooltipIcon id="tooltip-progresso-realizado" />
                            <b-tooltip
                                custom-class="tooltip-production-progress-op"
                                target="tooltip-progresso-realizado"
                                triggers="hover"
                                positioning="topright"
                                placement="topright"
                            >
                                <div class="title-tooltip-production-progress-op">
                                    {{ $t('OperationProgress.ForecastProgress') }}
                                </div>
                                <div class="info-tooltip-production-progress-op">
                                    {{ $t('OperationProgress.ForecastProgressTooltip') }}
                                </div>
                            </b-tooltip>
                        </div>
                        <generate-chart
                            v-if="renderCharts"
                            id="dc-pie-chart-foreseen"
                            :order="order"
                            type="1"
                            :listProgress="percentagesListCompleted"
                        />
                        <div class="col-indicador">
                            <p class="indicador-chart">Total</p>
                            <p class="value-indicador-chart">
                                {{ percentCompleted }}
                            </p>
                        </div>

                        <div class="legenda">
                            <div class="item-legenda legenda-realizado">
                                <div class="circle"></div>
                                <p class="dados-legenda"> {{ $t('OperationProgress.Completed') }}</p>
                            </div>
                            <div
                                class="item-legenda legenda-excedente"
                                v-if="parseFloat(order.time_consumed) > 1"
                            >
                                <div class="circle"></div>
                                <p class="dados-legenda">{{$t('OperationProgress.OverTime')}}</p>
                            </div>
                        </div>
                    </b-card>
                </b-col>

                <b-col cols="12" lg="6" xl="6" class="mt-2 mt-lg-0 mt-xl-0 order-2 order-lg-1 order-xl-1">
                    <b-card class="card-indicators">
                        <b-row class="row-indicators">
                            <b-col md="12">
                                <p class="title-chart">{{$t('OperationProgress.ForcastTime')}}</p>
                            </b-col>
                            <b-col md="4" class="dados">
                                <p class="sub-title-chart">
                                    {{$t('OperationProgress.ProductiveTime')}}
                                </p>
                                <span class="item-dado">{{
                                    formatHour(order.produtive_planned_time)
                                }}</span>
                            </b-col>
                            <b-col md="4" class="dados">
                                <p class="sub-title-chart">Total</p>
                                <span class="item-dado">{{
                                    formatHour(order.total_planned_time)
                                }}</span>
                            </b-col>
                            <b-col md="12">
                                <p class="title-chart">{{$t('OperationProgress.MyWork')}}</p>
                            </b-col>
                            <b-col md="4" class="dados">
                                <p class="sub-title-chart">Setup</p>
                                <span class="item-dado">{{
                                    formatHour(order.progress_time_setup)
                                }}</span>
                            </b-col>
                            <b-col md="4" class="dados">
                                <p class="sub-title-chart">{{$t('ResourceLoading.Running')}}</p>
                                <span class="item-dado">{{
                                    formatHour(order.progress_time_running)
                                }}</span>
                            </b-col>
                            <b-col md="4" class="dados">
                                <p class="sub-title-chart">{{$t('ResourceLoading.Stopped')}}</p>
                                <span class="item-dado">{{
                                    formatHour(order.progress_time_stopped)
                                }}</span>
                            </b-col>
                            <b-col md="12">
                                <p class="title-chart">{{$t('OperationProgress.TotalTime')}}</p>
                            </b-col>
                            <b-col md="4" class="dados">
                                <p class="sub-title-chart">
                                    {{$t('OperationProgress.NetTime')}}
                                </p>
                                <span class="item-dado">{{
                                    formatHour(order.total_lead_time)
                                }}</span>
                            </b-col>
                            <b-col md="4" class="dados">
                                <p class="sub-title-chart">{{$t('ResourceLoading.Completed')}}</p>
                                <span class="item-dado">{{
                                    formatHour(order.total_running_time)
                                }}</span>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col cols="6" lg="3" xl="3" class="web order-1 order-lg-2 order-xl-2">
                    <b-card class="card-chart">
                        <div class="title-icon">
                            <p class="title-chart">{{$t('OperationProgress.RealProgress')}}</p>
                            <imgTooltipIcon id="tooltip-progresso-produtivo" />
                            <b-tooltip
                                custom-class="tooltip-production-progress-op"
                                target="tooltip-progresso-produtivo"
                                triggers="hover"
                            >
                                <div class="title-tooltip-production-progress-op">
                                    {{$t('OperationProgress.RealProgress')}}
                                </div>
                                <div class="info-tooltip-production-progress-op">
                                    {{$t('OperationProgress.RealProgressTooltip')}}
                                </div>
                            </b-tooltip>
                        </div>
                        <generate-chart
                            v-if="renderCharts"
                            id="dc-pie-chart-productive"
                            type="1"
                            :order="order"
                            :listProgress="percentagesListProductive"
                        />
                        <div class="col-indicador">
                            <p class="indicador-chart">Total</p>
                            <p class="value-indicador-chart">
                                {{ percentProductive }}
                            </p>
                        </div>

                        <div class="legenda">
                            <div class="item-legenda legenda-produtivo">
                                <div class="circle"></div>
                                <p class="dados-legenda">{{$t('OperationProgress.Productive')}}</p>
                            </div>
                            <div
                                class="item-legenda legenda-excedente"
                                v-if="parseFloat(order.completed_progress) > 1"
                            >
                                <div class="circle"></div>
                                <p class="dados-legenda">{{$t('OperationProgress.OverTime')}}</p>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

            <b-row class="col-progress quantidade" v-if="isControlByHour == false && infoMultipleOrders == false">
                <b-col md="6" lg="4" xl="4">
                    <b-card class="card-chart">
                        <div class="title-icon">
                            <p class="title-chart">{{$t('Progress')}}</p>
                        </div>
                        <b-row class="row-cards-progress">
                            <b-col cols="6" class="chart">
                                <generate-chart
                                    v-if="renderCharts"
                                    id="dc-pie-chart-progress"
                                    :order="order"
                                    :listProgress="percentagesListProgress"
                                />
                                <div class="col-indicador-small">
                                    <p class="value-indicador-chart">
                                        {{ progressoPrevisto }}
                                    </p>
                                </div>
                                <p class="legend-chart">{{$t('ProductionProgress.Forecast')}}</p>
                            </b-col>

                            <b-col cols="6" class="chart">
                                <generate-chart
                                    v-if="renderCharts"
                                    id="dc-pie-chart-real-progress"
                                    :order="order"
                                    :listProgress="percentagesListConcluded"
                                />
                                <div class="col-indicador-small">
                                    <p class="value-indicador-chart">
                                        {{ progressoReal }}
                                    </p>
                                </div>
                                <p class="legend-chart">Real</p>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col cols="12" lg="4" xl="4" class="mt-2 mt-lg-0 mt-xl-0 order-2 order-lg-1 order-xl-1">
                    <b-card class="card-indicators h-100">
                        <b-row class="row-indicators">
                            <b-col cols="12">
                                <p class="title-chart">{{$t('ProductionProgress.Quantity')}}</p>
                            </b-col>
                            <b-col md="3" lg="6" xl="6" class="dados d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-chart">{{$t('ProductionProgress.Planned')}}</p>
                                <span class="item-dado">{{
                                    order.planned_quantity
                                }}</span>
                            </b-col>
                            <b-col md="3" lg="6" xl="6" class="dados d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-chart">{{$t('ProductionProgress.Produced2')}}</p>
                                <span class="item-dado">{{
                                    order.produced_quantity
                                }}</span>
                            </b-col>
                            <b-col md="3" lg="6" xl="6" class="dados d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-chart">{{$t('ProductionProgress.Rejection')}}</p>
                                <span class="item-dado">{{
                                    order.refuse_quantity
                                }}</span>
                            </b-col>
                            <b-col md="3" lg="6" xl="6" class="dados d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-chart">{{$t('ProductionProgress.Rework')}}</p>
                                <span class="item-dado">{{
                                    order.rework_quantity
                                }}</span>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col md="6" lg="4" xl="4" class="mt-2 mt-md-0 mt-lg-0 mt-xl-0 order-1 order-lg-2 order-xl-2">
                    <b-card class="card-chart status">
                        <div class="title-icon">
                            <p class="title-chart">Status</p>
                        </div>
                        <div class="chart-bar-status">
                            <div
                                v-for="status in order.status"
                                :key="status.index"
                                class="bar"
                                :style="`
                                width:${(
                                    parseFloat(status.percent) * 100
                                ).toFixed(2)}%;
                                background-color:${status.color}
                            `"
                            ></div>
                        </div>
                        <div class="legend-status">
                            <b-row class="align-items-stretch">
                                <template
                                    v-for="(status, index) in order.status"
                                >
                                    <b-col cols="4" :key="'col_' + index">
                                        <div class="legend executando h-100">
                                            <div
                                                class="color-bar"
                                                :style="`background-color:${status.color}`"
                                            ></div>
                                            <div class="info-legend">
                                                <div class="title">
                                                    {{ status.name }}
                                                </div>
                                                <div class="indicator">
                                                    {{
                                                        (
                                                            parseFloat(
                                                                status.percent
                                                            ) * 100
                                                        ).toFixed(2)
                                                    }}% -
                                                    {{
                                                        parseFloat(
                                                            status.hours
                                                        ).toFixed(2)
                                                    }}
                                                    h
                                                </div>
                                            </div>
                                        </div>
                                    </b-col>

                                    <b-col
                                        md="12"
                                        v-if="(index + 1) % 3 == 0 && order.status.length > 3"
                                        :key="'divider_' + index"
                                    >
                                        <div class="line"></div>
                                    </b-col>
                                </template>
                            </b-row>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

            <b-row v-if="infoMultipleOrders == false">
                <b-col md="12" class="col-btn-infos">
                    <b-button
                        :class="['btn-infos', infoOperacao && 'active']"
                        @click="habilitaInfos('operacao')"
                    >
                        <imgConfigIcon fill="#998F8A" />
                        <span class="mr-25 align-middle"
                            >{{$t('OperationProgress.OperationInformation')}}</span
                        >
                    </b-button>
                    <b-button
                        :class="['btn-infos', infoOrdem && 'active']"
                        @click="habilitaInfos('ordem')"
                    >
                        <imgPasteIcon />
                        <span class="mr-25 align-middle"
                            >{{$t('OperationProgress.OrderInformation')}}</span
                        >
                    </b-button>
                    <b-button
                        :class="['btn-infos', infoAtributos && 'active']"
                        @click="habilitaInfos('atributos')"
                    >
                        <imgTagIcon />
                        <span class="mr-25 align-middle">{{$t('OperationProgress.Attributes')}}</span>
                    </b-button>
                    <b-button
                        :class="['btn-infos', infoApontamentos && 'active']"
                        @click="habilitaInfos('apontamentos')"
                    >
                        <imgListIcon />
                        <span class="mr-25 align-middle"
                            >{{$t('ProductionProgress.title')}}</span
                        >
                    </b-button>
                    <b-button
                        :class="['btn-infos', infoObservacoes && 'active']"
                        @click="habilitaInfos('observacoes')"
                    >
                        <imgWarningIcon />
                        <span class="mr-25 align-middle">{{$t('OperationProgress.Observations')}}</span>
                    </b-button>
                    <b-button
                        :class="['btn-infos', infoLinks && 'active']"
                        @click="habilitaInfos('links')"
                    >
                        <linkPage />
                        <span class="mr-25 align-middle">Links</span>
                    </b-button>
                </b-col>
            </b-row>
            <!-- original -->
            <b-row v-if="infoOperacao">
                <b-col md="12">
                    <b-card class="info-apontamento">
                        <b-row>
                            <b-col md="4" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">{{$t('ProductionProgress.OperationNumber')}}</p>
                                <span class="item-dado ml-auto">{{ order.op_no }}</span>
                            </b-col>

                            <b-col md="4" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">{{$t('ProductionProgress.OperationName')}}</p>
                                <span class="item-dado ml-auto">{{
                                    order.operation_name
                                }}</span>
                            </b-col>

                            <b-col md="4" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">
                                    {{$t('ProductionProgress.ForecastSetup')}}
                                </p>
                                <span class="item-dado ml-auto">{{
                                    order.setup_start
                                        | moment("DD-MM-YYYY HH:mm")
                                }}</span>
                            </b-col>

                            <b-col md="4" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">{{$t('ProductionProgress.ProductCode')}}</p>
                                <span class="item-dado ml-auto">{{
                                    order.part_no
                                }}</span>
                            </b-col>

                            <b-col md="4" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">{{$t('ProductionProgress.Resource')}}</p>
                                <span class="item-dado ml-auto">{{
                                    order.resource_name
                                }}</span>
                            </b-col>

                            <b-col md="4" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">
                                    {{$t('ProductionProgress.ForecastStart')}}
                                </p>
                                <span class="item-dado ml-auto">{{
                                    order.start_time
                                        | moment("DD-MM-YYYY HH:mm")
                                }}</span>
                            </b-col>

                            <b-col md="4" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">{{$t('ProductionProgress.ProductName')}}</p>
                                <span class="item-dado ml-auto">{{
                                    order.product
                                }}</span>
                            </b-col>

                            <b-col md="4" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">{{$t('ProductionProgress.Workcenter')}}</p>
                                <span class="item-dado ml-auto">{{
                                    order.workcenter ? order.workcenter : "-"
                                }}</span>
                            </b-col>

                            <b-col md="4" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">
                                    {{$t('ProductionProgress.ForecastEnd')}}
                                </p>
                                <span class="item-dado ml-auto">{{
                                    order.end_time | moment("DD-MM-YYYY HH:mm")
                                }}</span>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>

            <b-row v-if="infoMultipleOrders">
                <b-col class="col-12 col-lg-6" v-for="(item,index) in ordersCardList" :key="index">
                    <b-card class="info-cards-orders-pontamento">
                        <div class="col-order-name">
                            <span class="order-name">{{ item.order_no }}</span>
                        </div>

                        <div class="col-percent">
                            <div class="d-flex justify-content-between">
                                <span class="text-label">{{ $t('OperationProgress.RealProgress') }}</span>
                                <span class="percent">{{ parseInt(item.real_progress)}}%</span>
                            </div>

                            <b-progress class="mb-2">
                                <b-progress-bar :value="parseInt(item.real_progress)" :max="100" :style="`background-color: ${order.status_color} !important;`">
                                </b-progress-bar>
                            </b-progress>
                        </div>
                        <b-row class="div-detail-orders d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                            <b-col cols="12" sm="6" md="6" lg="6" xl="6" class="detail-orders d-flex flex-row flex-sm-column flex-md-column order-1 order-sm-1 flex-lg-column flex-xl-column justify-content-between justify-content-sm-center">
                                <span class="text-label">{{ $t('OperationProgress.OperationNumber') }}</span>
                                <span class="info-order">{{ item.op_no }}</span>
                            </b-col>

                            <b-col cols="12" sm="6" md="6" lg="6" xl="6" class="detail-orders d-flex flex-row flex-sm-column flex-md-column flex-lg-column flex-xl-column order-5 order-sm-2 justify-content-between justify-content-sm-center">
                                <span class="text-label">{{ $t('ProductionProgress.Planned') }}</span>
                                <span class="info-order">{{ parseInt(item.planned_quantity) }}</span>
                            </b-col>

                            <b-col cols="12" sm="6" md="6" lg="6" xl="6" class="detail-orders d-flex flex-row flex-sm-column flex-md-column flex-lg-column flex-xl-column order-2 order-sm-3 justify-content-between justify-content-sm-center">
                                <span class="text-label">{{ $t('OperationProgress.Product') }}</span>
                                <span class="info-order">{{ item.product }}</span>
                            </b-col>

                            <b-col cols="12" sm="6" md="6" lg="6" xl="6" class="detail-orders d-flex flex-row flex-sm-column flex-md-column flex-lg-column flex-xl-column order-6 order-sm-4 justify-content-between justify-content-sm-center">
                                <span class="text-label">{{ $t('ProductionProgress.Produced2') }}</span>
                                <span class="info-order">{{ parseInt(item.produced_quantity)}}</span>
                            </b-col>

                            <b-col cols="12" sm="6" md="6" lg="6" xl="6" class="detail-orders d-flex flex-row flex-sm-column flex-md-column flex-lg-column flex-xl-column order-3 order-sm-5 justify-content-between justify-content-sm-center">
                                <span class="text-label">{{ $t('Operator.OperationDescription') }}</span>
                                <span class="info-order">{{ item.operation_name }}</span>
                            </b-col>

                            <b-col cols="12" sm="6" md="6" lg="6" xl="6" class="detail-orders d-flex flex-row flex-sm-column flex-md-column flex-lg-column flex-xl-column order-7 order-sm-6 justify-content-between justify-content-sm-center">
                                <span class="text-label">{{ $t('ProductionProgress.Rejection') }}</span>
                                <span class="info-order">{{ parseInt(item.refuse) }}</span>
                            </b-col>

                            <b-col cols="12" sm="6" md="6" lg="6" xl="6" class="detail-orders d-flex flex-row flex-sm-column flex-md-column flex-lg-column flex-xl-column order-4 order-sm-7 justify-content-between justify-content-sm-center">
                                <span class="text-label">{{ $t('ProductionProgress.Resource') }}</span>
                                <span class="info-order">{{ item.resource }}</span>
                            </b-col>

                            <b-col cols="12" sm="6" md="6" lg="6" xl="6" class="detail-orders d-flex flex-row flex-sm-column flex-md-column flex-lg-column flex-xl-column order-8 order-sm-8 justify-content-between justify-content-sm-center">
                                <span class="text-label">{{ $t('ProductionProgress.Rework') }}</span>
                                <span class="info-order">{{ parseInt(item.rework) }}</span>
                            </b-col>


                        </b-row>
                    </b-card>
                </b-col>
            </b-row>

            <b-row v-else-if="infoOrdem">
                <b-col md="12">
                    <b-card class="info-apontamento">
                        <b-row>
                            <b-col md="6" lg="3" xl="3" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">{{$t('orderView.Quantity')}}</p>
                                <span class="item-dado ml-auto">{{
                                    order.planned_quantity
                                }}</span>
                            </b-col>
                            <b-col md="6" lg="3" xl="3" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">{{$t('orderView.Priority')}}</p>
                                <span class="item-dado ml-auto">{{
                                    order.priority
                                }}</span>
                            </b-col>

                            <b-col md="6" lg="3" xl="3" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">
                                    {{$t('orderView.EarliestStartDate')}}
                                </p>
                                <span class="item-dado ml-auto">{{
                                    order.earliest_start_date
                                        | moment("DD-MM-YYYY HH:mm")
                                }}</span>
                            </b-col>
                            <b-col md="6" lg="3" xl="3" class="info d-md-block d-lg-block d-xl-block">
                                <p class="sub-title-info">{{$t('orderView.DueDate')}}</p>
                                <span class="item-dado ml-auto">{{
                                    order.due_date | moment("DD-MM-YYYY HH:mm")
                                }}</span>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-else-if="infoAtributos">
                <b-col md="12">
                    <b-card class="info-apontamento">
                        <b-row>
                            <b-col
                                v-for="(item, index) in orderedAttributes"
                                :key="'custom-attribute-apontamento-' + index"
                                md="4"
                                class="info d-md-block d-lg-block d-xl-block"
                            >
                                <p class="sub-title-info">
                                    {{ item ? item.name : '' }}
                                </p>
                                <span class="item-dado ml-auto">
                                    {{ item ? item.attr_value : '' }}
                                </span>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-else-if="infoObservacoes">
                <b-col md="12">
                    <b-card class="info-apontamento">
                        <b-row>
                            <b-col md="12" class="info">
                                <p class="sub-title-info">{{ order.note }}</p>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-else-if="infoLinks">
                <b-col md="12">
                    <b-card class="info-apontamento links">
                        <b-row>
                            <b-col md="12" >
                                <a target="_blank" @click="openUrl(link.url, link.type)" v-for="(link, index) in linksList" :key="index" class="info card-files-links" >
                                    <pdfFile v-if="link.extension == 'pdf'"/>
                                    <wordFile v-else-if="['doc','docm','docx','dot','dotx'].includes(link.extension)"/>
                                    <mp3File v-else-if="['mp3','m4a'].includes(link.extension)"/>
                                    <xmlFile v-else-if="['xla','xlm', 'xls','xlsm','xlsx','xlt','xltm','xltx'].includes(link.extension)"/>
                                    <textFile v-else-if="link.extension == 'txt'"/>
                                    <mp4File v-else-if="['mp4','mpeg','wmv'].includes(link.extension)"/>
                                    <imgFile v-else-if="['png','jpeg','jpg'].includes(link.extension) || link.type == 'base64'"/>
                                    <zipFile v-else-if="['zip','rar'].includes(link.extension)"/>
                                    <webFile v-else/>
                                    <span :id="`tooltip-link-${index}`">{{ link.name }}.{{ link.extension }}</span>
                                    <b-tooltip
                                        :target="`tooltip-link-${index}`"
                                        triggers="hover blur"
                                        positioning="top"
                                        placement="top"
                                        boundary-padding="0"
                                        class="tooltip-link"
                                    >
                                        <div class="info-tooltip-link">
                                            {{link.name}}.{{ link.extension }}
                                        </div>
                                    </b-tooltip>
                                </a>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-else-if="infoApontamentos">
                <b-col md="12">
                    <b-card class="info-apontamento">
                        <table-default
                            class="table-apontamentos"
                            v-if="rows.length > 0"
                            :rows="rows"
                            :fields="
                                isControlByHour
                                    ? columnsHora
                                    : columnsQuantidade
                            "
                            :stickyHeader="false"
                            :striped="true"
                            :currentPage="currentPage"
                            @alteraPaginaListagem="currentPage = $event"
                        >
                            <template v-slot:cell(status)="data">
                                <b-badge
                                    :style="
                                        getBackgroundColorLegend(
                                            data.item.status
                                        )
                                    "
                                >
                                    {{ data.item.status }}
                                </b-badge>
                            </template>

                            <template v-slot:cell(event_time)="data">
                                {{ formataData(data.value) }}

                            </template>

                            <template v-slot:cell(total_completed)="data">
                                {{ formatHour(data.value) }}
                            </template>

                            <template v-slot:cell(action)="data">
                                <div
                                    class="icon-trash"
                                    v-if="data.index == 0 && currentPage == 1"
                                >
                                    <imgTrashIcon
                                        @click="
                                            openModalDelete(
                                                data.item.real_id
                                            )
                                        "
                                    />
                                </div>
                                <div v-else>-</div>
                            </template>

                            <template v-slot:cell(created_at)="data">
                                {{ formatTime(data.item.event_time) }}
                            </template>

                            <template v-slot:cell(produced_quantity)="data">
                                {{
                                    parseInt(data.item.produced_quantity)
                                }}
                            </template>

                            <template v-slot:cell(rework_quantity)="data">
                                {{
                                    parseInt(data.item.rework_quantity)
                                }}
                            </template>

                            <template v-slot:cell(refuse_quantity)="data">
                                {{
                                    parseInt(data.item.refuse_quantity)
                                }}
                            </template>
                        </table-default>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCol,
    BRow,
    BButton,
    BLink,
    BAlert,
    BTableSimple,
    BTbody,
    BCardGroup,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BModal,
    VBModal,
    BFormSelect,
    BCardText,
    BFormTextarea,
    BNavItem,
    BPagination,
    BImg,
    BTooltip,
    BProgress,
    BProgressBar
} from "bootstrap-vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import { VueGoodTable } from "vue-good-table";
import CardActions from "../../..//@core/components/card-actions/CardActions.vue";
import imgTooltipIcon from "@/assets/images/pages/tooltip-icon.svg";
import imgWarningIcon from "@/assets/images/pages/warning-icon.svg";
import imgTagIcon from "@/assets/images/pages/tag-icon.svg";
import imgPasteIcon from "@/assets/images/pages/paste-icon.svg";
import imgListIcon from "@/assets/images/pages/list-icon.svg";
import imgTrashIcon from "@/assets/images/pages/trash-icon.svg";
import imgConfigIcon from "@/assets/images/pages/config-icon.svg";
import calendarIcon from "@/assets/images/pages/calendar-icon.svg";
import timeIcon from "@/assets/images/pages/time-icon.svg";
import TableDefault from "@core/components/table-default/TableDefault.vue";
import FormSelect from "../../../@core/components/form-select/FormSelect.vue";
import FormPicker from "../../../@core/components/form-picker/FormPicker.vue";
import FormInput from "../../../@core/components/form-input/FormInput.vue";
import GenerateChart from "./GenerateChart.vue";
import ModalDeleteApontamento from "@/@core/components/b-modal/ModalDeleteApontamento.vue";
import Breadcrumb from "../../../layouts/components/Breadcrumb.vue";
import pdfFile from "@/assets/images/pages/pdf-file.svg"
import webFile from "@/assets/images/pages/web-file.svg"
import wordFile from "@/assets/images/pages/word-file.svg"
import mp3File from "@/assets/images/pages/mp3-file.svg"
import xmlFile from "@/assets/images/pages/xml-file.svg"
import textFile from "@/assets/images/pages/text-file.svg"
import mp4File from "@/assets/images/pages/mp4-file.svg"
import imgFile from "@/assets/images/pages/img-file.svg"
import zipFile from "@/assets/images/pages/zip-file.svg"
import linkPage from "@/assets/images/pages/integration-page-icon.svg"
import { now } from '@vueuse/shared';
import { mapMutations } from "vuex";

export default {
    components: {
    GenerateChart,
    BCard,
    BCol,
    BRow,
    BButton,
    BLink,
    BAlert,
    BTableSimple,
    BTbody,
    BCardGroup,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BModal,
    BCardText,
    BFormSelect,
    flatPickr,
    ToastificationContent,
    FeatherIcon,
    BFormTextarea,
    BNavItem,
    VueGoodTable,
    BPagination,
    Cleave,
    CardActions,
    BImg,
    imgTooltipIcon,
    imgWarningIcon,
    imgTagIcon,
    imgPasteIcon,
    imgListIcon,
    imgConfigIcon,
    TableDefault,
    calendarIcon,
    timeIcon,
    FormSelect,
    FormPicker,
    FormInput,
    BTooltip,
    imgTrashIcon,
    ModalDeleteApontamento,
    Breadcrumb,
    BProgress,
    BProgressBar,
    pdfFile,
    webFile,
    wordFile,
    mp3File,
    xmlFile,
    textFile,
    mp4File,
    imgFile,
    zipFile,
    linkPage
},
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    data() {
        return {
            ordersCardList:[],
            showMessageErrorFields: false,
            apontamentoId: null,
            forseenGraph: true,
            currentPage: 1,
            moment: moment,
            hourSetup: "",
            hourIniciado: "",
            hourExecutando: "",
            hourForaTurno: "",
            porcentagemExecutando: "",
            porcentagemInciado: "",
            porcentagemSetup: "",
            porcentagemForaTurno: "",
            progressoPrevisto: "",
            progressoReal: "",
            percentCompleted: "",
            percentProductive: "",
            requestDateTime: false,
            isControlByHour: false,
            allowChangeResource: false,
            validation: {
                modalSetup_resource_id: {
                    msgError: "",
                    isError: false,
                },
                modalSetup_operation_date: {
                    msgError: "",
                    isError: false,
                },
                modalSetup_operation_time: {
                    msgError: "",
                    isError: false,
                },
                modalRunning_produzido: {
                    msgError: "",
                    isError: false,
                },
                modalRunning_refugo: {
                    msgError: "",
                    isError: false,
                },
                modalRunning_motivo_refugo: {
                    msgError: "",
                    isError: false,
                },
                modalRunning_retrabalho: {
                    msgError: "",
                    isError: false,
                },
                modalRunning_resource_id: {
                    msgError: "",
                    isError: false,
                },
                modalRunning_operation_date: {
                    msgError: "",
                    isError: false,
                },
                modalRunning_operation_time: {
                    msgError: "",
                    isError: false,
                },
                modalStop_produzido: {
                    msgError: "",
                    isError: false,
                },
                modalStop_refugo: {
                    msgError: "",
                    isError: false,
                },
                modalStop_motivo_refugo: {
                    msgError: "",
                    isError: false,
                },
                modalStop_retrabalho: {
                    msgError: "",
                    isError: false,
                },
                modalStop_resource_id: {
                    msgError: "",
                    isError: false,
                },
                modalStop_operation_date: {
                    msgError: "",
                    isError: false,
                },
                modalStop_operation_time: {
                    msgError: "",
                    isError: false,
                },
                modalStop_status_id: {
                    msgError: "",
                    isError: false,
                },
                modalFinish_produzido: {
                    msgError: "",
                    isError: false,
                },
                modalFinish_refugo: {
                    msgError: "",
                    isError: false,
                },
                modalFinish_motivo_refugo: {
                    msgError: "",
                    isError: false,
                },
                modalFinish_retrabalho: {
                    msgError: "",
                    isError: false,
                },
                modalFinish_operation_date: {
                    msgError: "",
                    isError: false,
                },
                modalFinish_operation_time: {
                    msgError: "",
                    isError: false,
                },
                modalFinish_resource_id: {
                    msgError: "",
                    isError: false,
                },
            },
            modalSetup: {
                resource_id: "",
                operation_date: "",
                operation_time: "",
            },
            modalRunning: {
                resource_id: "",
                operation_date: "",
                operation_time: "",
                produzido: "",
                refugo: "",
                motivo_refugo: "",
                retrabalho: "",
            },
            modalStop: {
                resource_id: "",
                operation_date: "",
                operation_time: "",
                status_id: "",
                produzido: "",
                refugo: "",
                motivo_refugo: "",
                retrabalho: "",
            },
            modalFinish: {
                operation_date: "",
                operation_time: "",
                produzido: "",
                refugo: "",
                motivo_refugo: "",
                retrabalho: "",
                resource_id: "",
            },
            errorSetup: false,
            infoOperacao: true, // TRUE PARA SAIR DO MODO ORDENS MÚLTIPLAS
            infoOrdem: false,
            infoAtributos: false,
            infoApontamentos: false,
            infoObservacoes: false,
            infoLinks:false,
            infoMultipleOrders: false, // TRUE PARA ENTRA DO MODO ORDENS MÚLTIPLAS
            linksList:[],
            prefix:
                localStorage.getItem("prefix") ||
                this.$router.currentRoute.params.prefix,
            currentSite: JSON.parse(localStorage.getItem("currentSite")),
            sendingForm: false,
            order: "",
            resource: null,
            operationProgress: [],
            lastOperationProgress: [],
            resourcesList: [],
            statusList: [],
            statusOptions: [],
            refuseStatus: [],
            timer: "",
            user_id: JSON.parse(localStorage.getItem("userData")).guid,
            status_id: null,
            resource_id: this.$router.currentRoute.params.resource_id,
            event_time: "",
            rejection_reason_id: 0,
            form_produced_quantity: 0,
            form_refuse: 0,
            form_rework: 0,
            statusSetup: null,
            statusStart: null,
            statusRunning: null,
            statusStop: null,
            statusFinish: null,
            chartLegend: null,
            attributesFields: [],
            pageLength: 10,
            dir: false,
            columnsHora: [
                {
                    label: 'ProductionProgress.IdOperationProgress',
                    key: "real_id",
                    sortable: false,
                },
                {
                    label: "STATUS",
                    key: "status",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.DateUpper',
                    key: "event_time",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.HourUpper',
                    key: "created_at",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.TotalCompletedUpper',
                    key: "total_completed",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.UserUpper',
                    key: "user",
                    sortable: false,
                },
                {
                    label: "",
                    key: "action",
                    sortable: false,
                },
            ],
            columnsQuantidade: [
                {
                    label:'ProductionProgress.IdOperationProgress',
                    key: "real_id",
                    sortable: false,
                },
                {
                    label: "STATUS",
                    key: "status",
                    sortable: false,
                },
                {
                    label:'ProductionProgress.OrderNoUpper',
                    key: "order_no",
                    sortable: false,
                },
                {
                    label:'ProductionProgress.OperationNoUpper',
                    key: "op_no",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.ProducedUppper',
                    key: "produced_quantity",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.RefuseUpper',
                    key: "refuse_quantity",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.ReworkUpper',
                    key: "rework_quantity",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.DateUpper',
                    key: "event_time",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.HourUpper',
                    key: "created_at",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.ResourceUpper',
                    key: "resource",
                    sortable: false,
                },
                {
                    label: 'ProductionProgress.UserUpper',
                    key: "user",
                    sortable: false,
                },
                {
                    label: "",
                    key: "action",
                    sortable: false,
                },
            ],
            rows: [],
            searchTerm: "",
            renderCharts: true,
            selectedOrders: [],
            labelSelectedOrders: [],
            preactorsOrdersId: [],
            intervalFunction: 0,
            disableButtonModal: true,
        };
    },
    watch: {
        forseenGraph() {
            this.forceRerenderCharts()
        }
    },
    beforeMount() {
        this.infoMultipleOrders = this.$router.currentRoute.params.multiple == 'true' ? true : false;
        this.infoOperacao = this.$router.currentRoute.params.multiple == 'true' ? false : true;
        this.selectedOrders = this.infoMultipleOrders ? this.$router.currentRoute.params.orders : [];
        this.preactorsOrdersId = this.$router.currentRoute.params.preactor_orders;

        if(this.$router.currentRoute.params.multiple == 'false')
            this.getOperationProgress();

        if(this.infoMultipleOrders)
            this.getOrders();
        else
            this.getOrder();

        this.getResources();
        this.getCurrentlyStatus();
        this.getAttributesFields();
        this.getRefuseStatus();
        this.getLinks();

        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if(modalId === 'modal-setup' || modalId === 'modal-running' || modalId === 'modal-stop' || modalId === 'modal-finish') {

                this.modalSetup = {
                    resource_id: "",
                    operation_date: "",
                    operation_time: "",
                };

                this.modalRunning = {
                    resource_id: "",
                    operation_date: "",
                    operation_time: "",
                    produzido: "",
                    refugo: "",
                    motivo_refugo: "",
                    retrabalho: "",
                };

                this.modalStop = {
                    resource_id: "",
                    operation_date: "",
                    operation_time: "",
                    status_id: "",
                    produzido: "",
                    refugo: "",
                    motivo_refugo: "",
                    retrabalho: "",
                };

                this.modalFinish = {
                    operation_date: "",
                    operation_time: "",
                    produzido: "",
                    refugo: "",
                    motivo_refugo: "",
                    retrabalho: "",
                    resource_id: "",
                };

                this.validation = {
                    modalSetup_resource_id: {
                        msgError: "",
                        isError: false,
                    },
                    modalSetup_operation_date: {
                        msgError: "",
                        isError: false,
                    },
                    modalSetup_operation_time: {
                        msgError: "",
                        isError: false,
                    },
                    modalRunning_produzido: {
                        msgError: "",
                        isError: false,
                    },
                    modalRunning_refugo: {
                        msgError: "",
                        isError: false,
                    },
                    modalRunning_motivo_refugo: {
                        msgError: "",
                        isError: false,
                    },
                    modalRunning_retrabalho: {
                        msgError: "",
                        isError: false,
                    },
                    modalRunning_resource_id: {
                        msgError: "",
                        isError: false,
                    },
                    modalRunning_operation_date: {
                        msgError: "",
                        isError: false,
                    },
                    modalRunning_operation_time: {
                        msgError: "",
                        isError: false,
                    },
                    modalStop_produzido: {
                        msgError: "",
                        isError: false,
                    },
                    modalStop_refugo: {
                        msgError: "",
                        isError: false,
                    },
                    modalStop_motivo_refugo: {
                        msgError: "",
                        isError: false,
                    },
                    modalStop_retrabalho: {
                        msgError: "",
                        isError: false,
                    },
                    modalStop_resource_id: {
                        msgError: "",
                        isError: false,
                    },
                    modalStop_operation_date: {
                        msgError: "",
                        isError: false,
                    },
                    modalStop_operation_time: {
                        msgError: "",
                        isError: false,
                    },
                    modalStop_status_id: {
                        msgError: "",
                        isError: false,
                    },
                    modalFinish_produzido: {
                        msgError: "",
                        isError: false,
                    },
                    modalFinish_refugo: {
                        msgError: "",
                        isError: false,
                    },
                    modalFinish_motivo_refugo: {
                        msgError: "",
                        isError: false,
                    },
                    modalFinish_retrabalho: {
                        msgError: "",
                        isError: false,
                    },
                    modalFinish_operation_date: {
                        msgError: "",
                        isError: false,
                    },
                    modalFinish_operation_time: {
                        msgError: "",
                        isError: false,
                    },
                    modalFinish_resource_id: {
                        msgError: "",
                        isError: false,
                    },
                };

                this.showMessageErrorFields = false;
            }
        });

        this.intervalFunction = setInterval(() => {
            let currentDate = new Date();
            if (this.isControlByHour) {
                var distance = this.order.last_event_time_by_user
                    ? currentDate.getTime() -
                      new Date(this.order.last_event_time_by_user).getTime()
                    : 0;
            } else {

                var distance = this.order.last_event_time
                    ? currentDate.getTime() -
                      new Date(this.order.last_event_time).getTime()
                    : 0;

            }
            let seconds = this.formatTimer(
                Math.floor((distance % (1000 * 60)) / 1000)
            );

            let minutes = this.formatTimer(
                Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            );

            let hours = this.formatTimer(
                Math.floor(distance / (1000 * 60 * 60))
            );

            this.timer =
                distance > 0
                    ? `${hours} : ${minutes} : ${seconds}`
                    : `0 : 0 : 0`;
        }, 1000);
    },
    destroyed() {
        clearInterval(this.intervalFunction);
    },
    computed: {
        percentagesListConcluded() {
            return [
                {
                    percent:
                        this.order.real_progress ? (this.order.real_progress > 1 ? 1 : this.order.real_progress) : 0,
                    title: "Produzido",
                },

                {
                    percent:
                        this.order.real_progress < 1
                            ? 1 - this.order.real_progress
                            : 0,
                    title: "Faltante",
                },
            ]
        },
        percentagesListProgress() {
            return [
                {
                    percent:
                        this.order.theoretical_progress ? (this.order.theoretical_progress > 1 ? 1 : this.order.theoretical_progress) : 0,
                    title: "Produzido",
                },

                {
                    percent:
                        this.order.theoretical_progress < 1
                            ? 1 - this.order.theoretical_progress
                            : 0,
                    title: "Faltante",
                },
            ]
        },
        percentagesListRealProgress() {
            return  [
                {
                    percent:
                        this.order.real_progress ? (this.order.real_progress > 1 ? 1 : this.order.real_progress) : 0,
                    title: "Produzido",
                },
                {
                    percent:
                        this.order.real_progress < 1
                            ? 1 - this.order.real_progress
                            : 0,
                    title: "Faltante",
                },
            ]
        },
        percentagesListProductive() {
            return [
                {
                    percent:
                        this.order.completed_progress ? (this.order.completed_progress > 1
                            ? 1
                            : this.order.completed_progress) : 0,
                    title: "Produzido",
                },
                {
                    percent:
                        this.order.completed_progress > 1
                            ? this.order.completed_progress - 1
                            : 0,
                    title: "Excendente",
                },
                {
                    percent:
                        this.order.completed_progress < 1
                            ? 1 - this.order.completed_progress
                            : 0,
                    title: "Faltante",
                },
            ]
        },
        percentagesListCompleted() {
            return [
                {
                    percent:
                        this.order.time_consumed ? (this.order.time_consumed > 1 ? 1 : this.order.time_consumed) : 0,
                    title: "Produzido",
                },
                {
                    percent:
                        this.order.time_consumed > 1
                            ? this.order.time_consumed - 1
                            : 0,
                    title: "Excendente",
                },
                {
                    percent:
                        this.order.time_consumed < 1
                            ? 1 - this.order.time_consumed
                            : 0,
                    title: "Faltante",
                },
            ]
        },
        orderedAttributes() {
            const tableList = this.attributesFields.filter((item, index) => {
                return item.native_name.split(" ")[0] == "Table";
            });
            tableList.forEach((item, index) => {
                item["attr_value"] = this.order[`att_${index + 1}`]
                    ? this.order[`att_${index + 1}`]
                    : "-";
            });
            const stringList = this.attributesFields.filter((item) => {
                return item.native_name.split(" ")[0] == "String";
            });
            stringList.forEach((item, index) => {
                item["attr_value"] = this.order[`string_attribute_${index + 1}`]
                    ? this.order[`string_attribute_${index + 1}`]
                    : "-";
            });
            const numericalList = this.attributesFields.filter((item) => {
                return item.native_name.split(" ")[0] == "Numerical";
            });
            numericalList.forEach((item, index) => {
                item["attr_value"] = this.order[
                    `numerical_attribute_${index + 1}`
                ]
                    ? this.order[`numerical_attribute_${index + 1}`]
                    : "-";
            });
            const list = [];
            tableList.forEach((item, index) => {
                list.push(
                    tableList[index],
                    stringList[index],
                    numericalList[index]
                );
            });
            return list;
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true;
                return this.dir;
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false;
            return this.dir;
        },
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        openUrl(url, type){
            if(type == 'base64'){
                var win = window.open();
                win.document.write('<iframe src="' + url  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
            }
            else{
                window.open(url, '_blank')
            }
        },
        getLinks(){
            this.$http.get(`/api/${this.prefix}/order/links?site_guid=${this.currentSite.guid}&preactor_order_id=${this.$router.currentRoute.params.order_id}`)
                .then(res => {
                    this.linksList = res.data
                })
        },
        formataData(date){

            let language = localStorage.getItem('language');

            if (date) {
                if(language == 'pt'){
                    return moment(date).format("DD/MM/YYYY");
                }
                else{
                    return moment(date).format("MM/DD/YYYY");
                }

            }
        },
        insertOptionDefaultInSelect(array){
            return [{ text: this.$t('Select'), value: '', disabled:true}, ...array]
        },
        onResize(e) {
            this.windowWidth = window.innerWidth;
            this.forceRerenderCharts()
        },
        forceRerenderCharts() {
            // Remove my-component from the DOM
            this.renderCharts = false;

            this.$nextTick(() => {
            // Add the component back in
            this.renderCharts = true;
            })
        },
        formataUltimoApontamento(datetime) {
            let language = localStorage.getItem('language');

            if (datetime) {
                if(language == 'pt'){
                    return moment(datetime).format("DD-MM-YYYY HH:mm");
                }
                else{
                    return moment(datetime).format("MM-DD-YYYY HH:mm");
                }

            }

        },
        cardIniciarParcialContinuarTitle() {
            if (this.isControlByHour) {
                if (
                    !this.order.last_event_time_by_user ||
                    this.order.last_status_by_user == "Setup"
                ) {
                    return this.$t('ProductionProgress.Start');
                }
                if (
                    [
                        "Executando",
                        "Running",
                        "Iniciado",
                        "Operação Finalizada",
                    ].includes(this.order.last_status_by_user)
                ) {
                    return this.$t('ProductionProgress.Partial');
                }
                return this.$t('ProductionProgress.Continue');
            }
            if (!this.order.last_event_time || this.order.last_status == "Setup") {
                return this.$t('ProductionProgress.Start');
            }
            if (
                [
                    "Executando",
                    "Running",
                    "Iniciado",
                    "Operação Finalizada",
                ].includes(this.order.last_status)
            ) {
                return this.$t('ProductionProgress.Partial');
            }
            return this.$t('ProductionProgress.Continue');
        },
        updateValue(campo, value) {
            const [parent, elem] = campo.split(".");
            this[parent][elem] = value;
        },
        habilitaInfos(tipo) {
            this.infoOperacao = false;
            this.infoOrdem = false;
            this.infoAtributos = false;
            this.infoApontamentos = false;
            this.infoObservacoes = false;
            this.infoLinks = false;
            if (tipo == "operacao") {
                this.infoOperacao = true;
            } else if (tipo == "ordem") {
                this.infoOrdem = true;
            } else if (tipo == "atributos") {
                this.infoAtributos = true;
            } else if (tipo == "apontamentos") {
                this.infoApontamentos = true;
            } else if (tipo == 'links'){
                this.infoLinks = true
            } else  {
                this.infoObservacoes = true;
            }
        },
        getResources() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.resourcesList = []
            this.$http
                .get(
                    `/api/${this.$router.currentRoute.params.prefix}/resource/workcenters?site_guid=${this.currentSite.guid}`
                )
                .then((response) => {
                   if(this.infoMultipleOrders){
                        response.data.response.forEach((resource) => {
                            if(!resource.control_by_hour){
                                this.resourcesList.push({
                                    text: resource.name,
                                    value: resource.preactor_resource_id,
                                    allow_change_resource:
                                        resource.allow_change_resource,
                                    request_date_time: resource.request_date_time,
                                });
                            }
                        });
                   }
                   else{
                        response.data.response.forEach((resource) => {
                            this.resourcesList.push({
                                text: resource.name,
                                value: resource.preactor_resource_id,
                                allow_change_resource:
                                    resource.allow_change_resource,
                                request_date_time: resource.request_date_time,
                            });
                        });
                    }

                    this.resource = this.resourcesList.find(
                        (el) =>
                            el.value ==
                            this.$router.currentRoute.params.resource_id
                    );

                    this.isControlByHour = response.data.response.find(
                        (element) =>
                            element.preactor_resource_id ==
                            this.$router.currentRoute.params.resource_id
                    ).control_by_hour;

                    this.allowChangeResource = response.data.response.find(
                        (element) =>
                            element.preactor_resource_id ==
                            this.$router.currentRoute.params.resource_id
                    ).allow_change_resource;
                    this.requestDateTime = response.data.response.find(
                        (element) =>
                            element.preactor_resource_id ==
                            this.$router.currentRoute.params.resource_id
                    ).request_date_time;

                    this.forceRerenderCharts();
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                });
        },
        getRefuseStatus() {
            this.$http
                .get(
                    `/api/${this.$router.currentRoute.params.prefix}/refuse-status?site_guid=${this.currentSite.guid}`
                )
                .then((response) => {
                    response.data.response.forEach((refuseStatus) => {
                        this.refuseStatus.push({
                            text: `${refuseStatus.code} - ${refuseStatus.name}`,
                            value: refuseStatus.id,
                        });
                    });
                    this.forceRerenderCharts()
                });
        },
        getOperationProgress() {
            this.$http
                .get(
                    `/api/${this.$router.currentRoute.params.prefix}/operation-progress?filterDays=600&preactorOrderId=${this.$router.currentRoute.params.order_id}&site_guid=${this.currentSite.guid}`
                )
                .then((res) => {
                    this.operationProgress = res.data.operationsProgress;
                    this.rows = res.data.operationsProgress;
                    this.forceRerenderCharts()
                });
        },
        getCurrentlyStatus() {
            this.$http
                .get(
                    `/api/${this.$router.currentRoute.params.prefix}/currently-status?site_guid=${this.currentSite.guid}`
                )
                .then((response) => {
                    response.data.response.forEach((status) => {
                        this.statusList.push({
                            text: status.name,
                            value: status.id,
                            color: status.color,
                        });
                        if (
                            status.status_classification != 0 &&
                            status.name != "Setup"
                        )
                            this.statusOptions.push({
                                text: status.name,
                                value: status.id,
                            });
                    });
                    this.setStatus();
                    this.forceRerenderCharts()
                });
        },
        getOrder() {
            this.getLinks()
            this.$http
                .get(
                    `/api/${this.$router.currentRoute.params.prefix}/order/${this.$router.currentRoute.params.order_id}?site_guid=${this.currentSite.guid}`
                )
                .then((response) => {
                    this.order = response.data.response;
                    this.forceRerenderCharts()
                    this.percentProductive = `${(
                        parseFloat(this.order.completed_progress) * 100
                    ).toFixed(0)}%`;

                    this.percentCompleted = `${(
                        parseFloat(this.order.time_consumed) * 100
                    ).toFixed(0)}%`;

                    this.progressoPrevisto = `${(
                        parseFloat(this.order.theoretical_progress) * 100
                    ).toFixed(0)}%`;

                    this.progressoReal = `${(
                        parseFloat(this.order.real_progress) * 100
                    ).toFixed(0)}%`;
                });
        },
        getOrders(){
            this.$http.get(`api/${this.$router.currentRoute.params.prefix}/operator/orders/multiple?site_guid=${this.currentSite.guid}&preactor_orders_ids=${this.preactorsOrdersId}`)
            .then((response) => {
                    this.order = response.data.response;
                    this.ordersCardList = response.data.response.orders
                    this.labelSelectedOrders = response.data.response.orders.map(item => item.order_no);
            })
        },
        getAttributesFields() {
            this.$http
                .get(
                    `/api/${this.$router.currentRoute.params.prefix}/attribute-field?site_guid=${this.currentSite.guid}`
                )
                .then((res) => {
                    this.attributesFields = res.data.response;
                });
        },
        getBackgroundColorLegend(status) {
            const color = this.statusList.find((el) => el.text == status);
            if (color)
                return `color:${color.color}; margin-right:3px; background-color:${color.color}20`;
            return `background-color:secondary`;
        },
        formatTimer(value) {
            let result = value.toString().length < 2 ? "0" + value : value;
            return isNaN(result) ? "00" : result;
        },
        setStatus() {
            this.statusSetup = this.statusList.filter(
                (el) => el.text === "Setup" || el.text === "Setup"
            )[0];
            this.statusStart = this.statusList.filter(
                (el) => el.text === "Iniciado" || el.text === "Started"
            )[0];
            this.statusRunning = this.statusList.filter(
                (el) => el.text === "Executando" || el.text === "Running"
            )[0];
            this.statusFinish = this.statusList.filter(
                (el) =>
                    el.text === "Operação Finalizada" || el.text === "Completed"
            )[0];
        },
        async getDadosModal(operation = null) {
            let currentDate = new Date();
            let validateProduzido = false;
            if (operation == "setup") {
                this.modalSetup.resource_id = !this.infoMultipleOrders ? this.order.resource_id : '';
                this.modalSetup.operation_date = moment().format("YYYY-MM-DD");
                this.modalSetup.operation_time = moment().format("HH:mm");

                if (this.requestDateTime) {

                    let setupDateHour = new Date(this.order.setup_start);

                    if (setupDateHour <= currentDate){
                        this.modalSetup.operation_date = moment(this.order.setup_start).format("YYYY-MM-DD");
                        this.modalSetup.operation_time = moment(this.order.setup_start).format("HH:mm");
                    }
                }
            }
            if (operation == "start") {
                this.modalRunning.resource_id = !this.infoMultipleOrders ? this.order.resource_id : '';
                this.modalRunning.operation_date = moment().format("YYYY-MM-DD");
                this.modalRunning.operation_time = moment().format("HH:mm");
                let parcial = false;

                if (
                    this.cardIniciarParcialContinuarTitle() == this.$t('ProductionProgress.Partial') && !this.isControlByHour && !this.infoMultipleOrders
                ) {
                        this.modalRunning.produzido = this.order.balance ? parseFloat(
                            this.order.balance
                        ) : this.modalRunning.produzido;
                        validateProduzido = this.order.balance ? true : false;
                        parcial = true;
                }

                if (this.cardIniciarParcialContinuarTitle() == this.$t('ProductionProgress.Continue') ){
                    parcial = true;
                }

                if (this.requestDateTime && !parcial) {
                    let startDateHour = new Date(this.order.start_time);

                    if (startDateHour <= currentDate){
                        this.modalRunning.operation_date = moment(this.order.start_time).format("YYYY-MM-DD");
                        this.modalRunning.operation_time = moment(this.order.start_time).format("HH:mm");
                    }
                }
                return validateProduzido;
            }
            if (operation == "stop") {
                this.modalStop.resource_id = !this.infoMultipleOrders ? this.order.resource_id : '';
                this.modalStop.operation_date = moment().format("YYYY-MM-DD");
                this.modalStop.operation_time = moment().format("HH:mm");
            }
            if (operation == "finish") {
                this.modalFinish.resource_id = !this.infoMultipleOrders ? this.order.resource_id : '';
                this.modalFinish.operation_date = moment().format("YYYY-MM-DD");
                this.modalFinish.operation_time = moment().format("HH:mm");

            }

            return validateProduzido;
        },
        async checkModalDataAndEnableSaveButton(operation = null) {
            this.disableButtonModal = true;
            await this.getDadosModal(operation).then(validateProduzido => {
                if(operation == "setup") {
                    if (
                        (!this.infoMultipleOrders ? this.modalSetup.resource_id == 0 ? true : this.modalSetup.resource_id ? true : false : true)
                        && (this.modalSetup.operation_date == 0 ? true : this.modalSetup.operation_date ? true : false)
                        && (this.modalSetup.operation_time == 0 ? true : this.modalSetup.operation_time ? true : false)
                    ) this.disableButtonModal = false;
                } else if(operation == "start") {
                    if (
                        (!this.infoMultipleOrders ? this.modalRunning.resource_id == 0 ? true : this.modalRunning.resource_id ? true : false : true)
                        && (this.modalRunning.operation_date == 0 ? true : this.modalRunning.operation_date ? true : false)
                        && (this.modalRunning.operation_time == 0 ? true : this.modalRunning.operation_time ? true : false)
                        && (validateProduzido ? this.modalRunning.produzido == 0 ? true : this.modalRunning.produzido ? true : false : true)
                    ) this.disableButtonModal = false;
                } else if(operation == "stop") {
                    if (
                        (!this.infoMultipleOrders ? this.modalStop.resource_id == 0 ? true : this.modalStop.resource_id ? true : false : true)
                        && (this.modalStop.operation_date == 0 ? true : this.modalStop.operation_date ? true : false)
                        && (this.modalStop.operation_time == 0 ? true : this.modalStop.operation_time ? true : false)
                    ) this.disableButtonModal = false;
                } else if(operation == "finish") {
                    if (
                        (!this.infoMultipleOrders ? this.modalFinish.resource_id == 0 ? true : this.modalFinish.resource_id ? true : false : true)
                        && (this.modalFinish.operation_date == 0 ? true : this.modalFinish.operation_date ? true : false)
                        && (this.modalFinish.operation_time == 0 ? true : this.modalFinish.operation_time ? true : false)
                    ) this.disableButtonModal = false;
                }

            });
        },
        setProducedQuantities() {
            this.form_refuse = 0;
            this.form_rework = 0;
            this.form_produced_quantity = 0;

            if (
                this.operationProgress.length > 0 &&
                ["Started", "Iniciado", "Running", "Executando"].includes(
                    this.order.last_status_by_user
                )
            ) {
                this.form_produced_quantity =
                    this.order.balance -
                        this.order.produced_quantity -
                        this.order.refuse_quantity >=
                    0
                        ? this.order.balance -
                          this.order.produced_quantity -
                          this.order.refuse_quantity
                        : 0;
            }
        },
        selectApontamentoId(id){
            this.apontamentoId = id;
        },
        openModalDelete(id){
            this.selectApontamentoId(id);
            this.$bvModal.show('modal-delete-apontamento');
        },
        deleteProductionProgress(id, preactor_order_id) {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.sendingForm = true;
            this.$http
                .delete(
                    `/api/${this.$router.currentRoute.params.prefix}/operation-progress/${id}?preactor_order_id=${preactor_order_id}&site_guid=${this.currentSite.guid}`
                )
                .then(() => {
                    this.showOverlay = true;
                    this.getOrder();
                    this.getResources();
                    this.getOperationProgress();
                    this.sendingForm = false;
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    return true;
                })
                .catch((error) => {
                    this.showToast(
                        "Error!",
                        "FrownIcon",
                        error.response.data.message,
                        "danger"
                    );
                    this.sendingForm = false;
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    return false;
                });

            this.$bvModal.hide('modal-delete-apontamento');
        },
        createNewOperationProgress(
            status,
            event_time,
            produced_quantity,
            refuse,
            rework,
            rejection_reason_id,
            resource_id,
            code_refuse = ''
        ) {
            this.infoMultipleOrders
            ? this.$http
                .post(
                    `/api/${this.$router.currentRoute.params.prefix}/operator/operation-progress/multiple`,
                    {
                        user_id: this.user_id,
                        site_guid: this.currentSite.guid,
                        resource_id: resource_id,
                        status_id: status,
                        produced_quantity: produced_quantity,
                        refuse: refuse,
                        rework: rework,
                        event_time: event_time,
                        reject_reason_id: rejection_reason_id,
                        code_refuse: code_refuse,
                        preactor_order_id: this.preactorsOrdersId,
                    }
                )
                .then(() => {
                    this.getOrders();
                    this.getResources();
                    this.sendingForm = false;
                    this.$refs["formModal"].hide();

                    this.form_produced_quantity = 0;
                    this.form_refuse = 0;
                    this.form_rework = 0;
                    this.rejection_reason_id = 0;
                    if(this.infoMultipleOrders){
                        this.showToast(this.$t('ProductionProgress.Success'),"FrownIcon", this.$t('ProductionProgress.OperationProgressRegistred'),'success');
                    } else {
                        this.showToast(this.$t('ProductionProgress.Success'),"FrownIcon", `${this.$t('ProductionProgress.OperationProgressRegistred')}: ${this.$t('ProductionProgress.Order')} ${this.order.order_no}`,'success')
                    }
                })
                .catch((error) => {
                    this.sendingForm = false;
                    this.showToast(
                        "Error!",
                        "FrownIcon",
                        error.response.data.message,
                        "danger"
                    );
                })
            : this.$http
                .post(
                    `/api/${this.$router.currentRoute.params.prefix}/operation-progress`,
                    {
                        order_id: this.order.id,
                        preactor_order_id: this.order.preactor_order_id,
                        site_guid: this.order.site_guid,
                        op_no: this.order.op_no,
                        user_id: this.user_id,
                        status_id: status,
                        order_no: this.order.order_no,
                        resource_id: resource_id,
                        event_time: event_time,
                        produced_quantity: produced_quantity,
                        refuse: refuse,
                        rework: rework,
                        percent_completed: 0,
                        rejection_reason_id: rejection_reason_id,
                    }
                )
                .then(() => {
                    this.getOrder();
                    this.getResources();
                    this.getOperationProgress();
                    this.sendingForm = false;
                    this.$refs["formModal"].hide();
                    this.form_produced_quantity = 0;
                    this.form_refuse = 0;
                    this.form_rework = 0;
                    this.rejection_reason_id = 0;
                    if(this.infoMultipleOrders){
                        this.showToast(this.$t('ProductionProgress.Success'),"FrownIcon", this.$t('ProductionProgress.OperationProgressRegistred'),'success');
                    } else {
                        this.showToast(this.$t('ProductionProgress.Success'),"FrownIcon", `${this.$t('ProductionProgress.OperationProgressRegistred')}: ${this.$t('ProductionProgress.Order')} ${this.order.order_no}`,'success')
                    }
                })
                .catch((error) => {
                    this.sendingForm = false;
                    this.showToast(
                        "Error!",
                        "FrownIcon",
                        error.response.data.message,
                        "danger"
                    );
                });
        },
        validationSubmit(typeModal) {
            let validate = true;

            const dataAtualFormatada = new Date(Date.now()).toISOString().substring(0,10)

            const horaUltimoApontamento = parseInt(new Date(
                this.isControlByHour ? this.order.last_event_time_by_user : !this.isControlByHour ? this.order.last_event_time : ''
            ).getTime().toString().substring(0,10))

            const horaModalSetup = parseInt(new Date(
                this.modalSetup.operation_date +
                    " " +
                    this.modalSetup.operation_time
            ).getTime().toString().substring(0,10))

            const horaModalRunning = parseInt(new Date(
                this.modalRunning.operation_date +
                    " " +
                    this.modalRunning.operation_time
            ).getTime().toString().substring(0,10))

            const horaModalStop = parseInt(new Date(
                this.modalStop.operation_date +
                    " " +
                    this.modalStop.operation_time
            ).getTime().toString().substring(0,10))

            const horaModalFinish = parseInt(new Date(
                this.modalFinish.operation_date +
                    " " +
                    this.modalFinish.operation_time
            ).getTime().toString().substring(0,10))

            const horaAtual = parseInt(new Date(Date.now()).getTime().toString().substring(0,10))

            if (typeModal == "modalSetup") {
                // Data Vazia
                if (this.modalSetup.operation_date == "") {
                    this.validation.modalSetup_operation_date.msgError =
                        this.$t('RequiredField');
                    this.validation.modalSetup_operation_date.isError = true;
                    validate = false;
                }
                // Data maior do que o dia atual
                else if(this.modalSetup.operation_date > dataAtualFormatada){
                    this.validation.modalSetup_operation_date.msgError =
                        this.$t('ProductionProgress.InvalidDate1');
                    this.validation.modalSetup_operation_date.isError = true;
                    validate = false;
                }
                // Data válida
                else {
                    this.validation.modalSetup_operation_date.msgError = "";
                    this.validation.modalSetup_operation_date.isError = false;
                }

                // Hora Vazia
                if (this.modalSetup.operation_time == "") {
                    this.validation.modalSetup_operation_time.msgError =
                        this.$t('RequiredField');
                    this.validation.modalSetup_operation_time.isError = true;
                    validate = false;
                }

                // Hora maior do que hora atual
                 else if (
                    this.modalSetup.operation_date <= dataAtualFormatada
                ){
                    if( horaModalSetup > horaAtual){
                            this.validation.modalSetup_operation_time.msgError =
                            this.$t('ProductionProgress.InvalidHour2');
                        this.validation.modalSetup_operation_time.isError = true;
                        validate = false;
                    } else {
                        this.validation.modalSetup_operation_time.msgError = "";
                        this.validation.modalSetup_operation_time.isError = false;
                    }
                }
                // Hora válida
                 else {
                    this.validation.modalSetup_operation_time.msgError = "";
                    this.validation.modalSetup_operation_time.isError = false;
                }
            }
            if (typeModal == "modalRunning") {


                if (!this.isControlByHour && !this.infoMultipleOrders) {
                    if( this.cardIniciarParcialContinuarTitle() == this.$t('ProductionProgress.Partial')){
                        if (this.modalRunning.produzido == 0 && this.modalRunning.refugo == 0 && this.modalRunning.retrabalho == 0) {
                            this.validation.modalRunning_produzido.msgError = this.$t('RequiredField');
                            this.validation.modalRunning_refugo.msgError = this.$t('RequiredField');
                            this.validation.modalRunning_retrabalho.msgError = this.$t('RequiredField');
                            this.validation.modalRunning_produzido.isError = true;
                            this.validation.modalRunning_refugo.isError = true;
                            this.validation.modalRunning_retrabalho.isError = true;
                            validate = false;

                            this.showMessageErrorFields = true;
                        } else {

                            this.validation.modalRunning_produzido.msgError = "";
                            this.validation.modalRunning_produzido.isError = false;
                            this.validation.modalRunning_refugo.msgError = "";
                            this.validation.modalRunning_refugo.isError = false;
                            this.validation.modalRunning_retrabalho.msgError = "";
                            this.validation.modalRunning_retrabalho.isError = false;

                            this.showMessageErrorFields = false;
                        }
                    }

                    if (
                        this.modalRunning.motivo_refugo == "" &&
                        this.modalRunning.refugo > 0
                    ) {
                        this.validation.modalRunning_motivo_refugo.msgError =
                            this.$t('RequiredField');
                        this.validation.modalRunning_motivo_refugo.isError = true;
                        validate = false;
                    } else {
                        this.validation.modalRunning_motivo_refugo.msgError =
                            "";
                        this.validation.modalRunning_motivo_refugo.isError = false;
                    }
                }

                if (this.modalRunning.resource_id == "") {
                    this.validation.modalRunning_resource_id.msgError =
                        this.$t('RequiredField');
                    this.validation.modalRunning_resource_id.isError = true;
                    validate = false;
                } else {
                    this.validation.modalRunning_resource_id.msgError = "";
                    this.validation.modalRunning_resource_id.isError = false;
                }

                // Data Vazia
                if (this.modalRunning.operation_date == "") {
                    this.validation.modalRunning_operation_date.msgError =
                        this.$t('RequiredField');
                    this.validation.modalRunning_operation_date.isError = true;
                    validate = false;
                }
                // Data Menor ou igual a último apontamento
                else if (
                    this.modalRunning.operation_date <
                    this.formatDate(this.isControlByHour ? this.order.last_event_time_by_user : !this.isControlByHour ? this.order.last_event_time : '')
                ) {
                    this.validation.modalRunning_operation_date.msgError =
                        this.$t('ProductionProgress.InvalidDateBiggerOrEqual');
                    this.validation.modalRunning_operation_date.isError = true;
                    validate = false;
                }
                // Data maior do que o dia atual
                else if(this.modalRunning.operation_date > dataAtualFormatada){
                    this.validation.modalRunning_operation_date.msgError =
                        this.$t('ProductionProgress.InvalidDate1');
                    this.validation.modalRunning_operation_date.isError = true;
                    validate = false;
                }
                // Data válida
                else {
                    this.validation.modalRunning_operation_date.msgError = "";
                    this.validation.modalRunning_operation_date.isError = false;
                }

                // Hora Vazia
                if (this.modalRunning.operation_time == "") {
                    this.validation.modalRunning_operation_time.msgError =
                        this.$t('RequiredField');
                    this.validation.modalRunning_operation_time.isError = true;
                    validate = false;
                }
                // Hora Menor ou igual a último apontamento
                else if (
                    this.modalRunning.operation_date <=
                    this.formatDate(this.isControlByHour ? this.order.last_event_time_by_user : !this.isControlByHour ? this.order.last_event_time : '')
                ) {
                    if (horaUltimoApontamento > horaModalRunning) {
                        this.validation.modalRunning_operation_time.msgError =
                            this.$t('ProductionProgress.InvalidHourBiggerOrEqual');
                        this.validation.modalRunning_operation_time.isError = true;
                        validate = false;
                    }
                    else if (horaModalRunning > horaAtual) {
                        this.validation.modalRunning_operation_time.msgError =
                            this.$t('ProductionProgress.InvalidHour2');
                        this.validation.modalRunning_operation_time.isError = true;
                        validate = false;
                    }
                    else {
                        this.validation.modalRunning_operation_time.msgError = "";
                        this.validation.modalRunning_operation_time.isError = false;
                    }
                }
                // Hora maior do que o dia atual
                else if (
                    this.modalRunning.operation_date <= dataAtualFormatada
                ){
                    if(horaModalRunning > horaAtual){
                        this.validation.modalRunning_operation_time.msgError =
                        this.$t('ProductionProgress.InvalidHour2');
                        this.validation.modalRunning_operation_time.isError = true;
                        validate = false;
                    } else {
                        this.validation.modalRunning_operation_time.msgError = "";
                        this.validation.modalRunning_operation_time.isError = false;
                    }
                }
                // Hora Válida
                else {
                    this.validation.modalRunning_operation_time.msgError = "";
                    this.validation.modalRunning_operation_time.isError = false;
                }
            }
            if (typeModal == "modalStop") {

                if (!this.isControlByHour && !this.infoMultipleOrders) {
                    if (
                        this.modalStop.motivo_refugo == "" &&
                        this.modalStop.refugo > 0
                    ) {
                        this.validation.modalStop_motivo_refugo.msgError =
                            this.$t('RequiredField');
                        this.validation.modalStop_motivo_refugo.isError = true;
                        validate = false;
                    } else if(
                        this.modalStop.motivo_refugo && (!this.modalStop.refugo || this.modalStop.refugo == 0)
                    )
                    {
                        this.validation.modalStop_refugo.msgError = this.$t('RequiredField');
                        this.validation.modalStop_refugo.isError = true;
                        validate = false;
                    }
                    else {
                        this.validation.modalStop_motivo_refugo.msgError = "";
                        this.validation.modalStop_motivo_refugo.isError = false;
                        this.validation.modalStop_refugo.msgError = "";
                        this.validation.modalStop_refugo.isError = false;
                    }
                }

                if (this.modalStop.resource_id == "") {
                    this.validation.modalStop_resource_id.msgError =
                        this.$t('RequiredField');
                    this.validation.modalStop_resource_id.isError = true;
                    validate = false;
                } else {
                    this.validation.modalStop_resource_id.msgError = "";
                    this.validation.modalStop_resource_id.isError = false;
                }

                // Data vazia
                if (this.modalStop.operation_date == "") {
                    this.validation.modalStop_operation_date.msgError =
                        this.$t('RequiredField');
                    this.validation.modalStop_operation_date.isError = true;
                    validate = false;
                }
                 // Data Menor ou igual a último apontamento
                else if (
                    this.modalStop.operation_date <
                    this.formatDate(this.isControlByHour ? this.order.last_event_time_by_user : !this.isControlByHour ? this.order.last_event_time : '')
                ) {
                    this.validation.modalStop_operation_date.msgError =
                        this.$t('ProductionProgress.InvalidDateBiggerOrEqual');
                    this.validation.modalStop_operation_date.isError = true;
                    validate = false;
                }
                // Data maior do que o dia atual
                else if(this.modalStop.operation_date > dataAtualFormatada){
                    this.validation.modalStop_operation_date.msgError =
                        this.$t('ProductionProgress.InvalidDate1');
                    this.validation.modalStop_operation_date.isError = true;
                    validate = false;
                }
                // Data válida
                else {
                    this.validation.modalStop_operation_date.msgError = "";
                    this.validation.modalStop_operation_date.isError = false;
                }

                // Hora vazia
                if (this.modalStop.operation_time == "") {
                    this.validation.modalStop_operation_time.msgError =
                        this.$t('RequiredField');
                    this.validation.modalStop_operation_time.isError = true;
                    validate = false;
                }
                 // Hora Menor ou igual a último apontamento
                else if (
                    this.modalStop.operation_date <=
                    this.formatDate(this.isControlByHour ? this.order.last_event_time_by_user : !this.isControlByHour ? this.order.last_event_time : '')
                ) {

                    if (horaUltimoApontamento > horaModalStop) {
                        this.validation.modalStop_operation_time.msgError =
                            this.$t('ProductionProgress.InvalidHourBiggerOrEqual');
                        this.validation.modalStop_operation_time.isError = true;
                        validate = false;
                    }
                    else if (horaModalStop > horaAtual) {
                        this.validation.modalStop_operation_time.msgError =
                            this.$t('ProductionProgress.InvalidHour2');
                        this.validation.modalStop_operation_time.isError = true;
                        validate = false;
                    }
                    else {
                        this.validation.modalStop_operation_time.msgError = "";
                        this.validation.modalStop_operation_time.isError = false;
                    }
                }
                // Hora maior do que o dia atual
                else if (
                    this.modalStop.operation_date <= dataAtualFormatada
                ){
                    if(horaModalStop > horaAtual){
                        this.validation.modalStop_operation_time.msgError =
                        this.$t('ProductionProgress.InvalidHour2');
                        this.validation.modalStop_operation_time.isError = true;
                        validate = false;
                    } else {
                        this.validation.modalStop_operation_time.msgError = "";
                        this.validation.modalStop_operation_time.isError = false;
                    }
                }
                // Hora válida
                else {
                    this.validation.modalStop_operation_time.msgError = "";
                    this.validation.modalStop_operation_time.isError = false;
                }


                if (this.modalStop.status_id == "") {
                    this.validation.modalStop_status_id.msgError =
                        this.$t('RequiredField');
                    this.validation.modalStop_status_id.isError = true;
                    validate = false;
                } else {
                    this.validation.modalStop_status_id.msgError = "";
                    this.validation.modalStop_status_id.isError = false;
                }
            }
            if (typeModal == "modalFinish") {
                if (!this.isControlByHour  && !this.infoMultipleOrders) {
                    if (
                        this.modalFinish.motivo_refugo == "" &&
                        this.modalFinish.refugo > 0
                    ) {
                        this.validation.modalFinish_motivo_refugo.msgError =
                            this.$t('RequiredField');
                        this.validation.modalFinish_motivo_refugo.isError = true;
                        validate = false;
                    } else if(
                        this.modalFinish.motivo_refugo && (!this.modalFinish.refugo || this.modalFinish.refugo == 0)
                    ) {
                        this.validation.modalFinish_refugo.msgError = this.$t('RequiredField');
                        this.validation.modalFinish_refugo.isError = true;
                        validate = false;
                    }
                    else {
                        this.validation.modalFinish_motivo_refugo.msgError = "";
                        this.validation.modalFinish_motivo_refugo.isError = false;
                        this.validation.modalFinish_refugo.msgError = "";
                        this.validation.modalFinish_refugo.isError = false;
                    }
                }

                if (this.modalFinish.resource_id == "") {
                    this.validation.modalFinish_resource_id.msgError =
                        this.$t('RequiredField');
                    this.validation.modalFinish_resource_id.isError = true;
                    validate = false;
                } else {
                    this.validation.modalFinish_resource_id.msgError = "";
                    this.validation.modalFinish_resource_id.isError = false;
                }

                // Data vazia
                if (this.modalFinish.operation_date == "") {
                    this.validation.modalFinish_operation_date.msgError =
                        this.$t('RequiredField');
                    this.validation.modalFinish_operation_date.isError = true;
                    validate = false;
                }
                // Data Menor ou igual a último apontamento
                else if (
                    this.modalFinish.operation_date <
                    this.formatDate(this.isControlByHour ? this.order.last_event_time_by_user : !this.isControlByHour ? this.order.last_event_time : '')
                ) {
                    this.validation.modalFinish_operation_date.msgError =
                        this.$t('ProductionProgress.InvalidDateBiggerOrEqual');
                    this.validation.modalFinish_operation_date.isError = true;
                    validate = false;
                }
                // Data maior do que o dia atual
                else if(this.modalFinish.operation_date > dataAtualFormatada){
                    this.validation.modalFinish_operation_date.msgError =
                        this.$t('ProductionProgress.InvalidDate1');
                    this.validation.modalFinish_operation_date.isError = true;
                    validate = false;
                }
                // Data válida
                else {
                    this.validation.modalFinish_operation_date.msgError = "";
                    this.validation.modalFinish_operation_date.isError = false;
                }

                // Hora vazia
                if (this.modalFinish.operation_time == "") {
                    this.validation.modalFinish_operation_time.msgError =
                        this.$t('RequiredField');
                    this.validation.modalFinish_operation_time.isError = true;
                    validate = false;
                }
                 // Hora Menor ou igual a último apontamento
                else if (
                    this.modalFinish.operation_date <=
                    this.formatDate(this.isControlByHour ? this.order.last_event_time_by_user : !this.isControlByHour ? this.order.last_event_time : '')
                ) {

                    if (horaUltimoApontamento > horaModalFinish) {
                        this.validation.modalFinish_operation_time.msgError =
                            this.$t('ProductionProgress.InvalidHourBiggerOrEqual');
                        this.validation.modalFinish_operation_time.isError = true;
                        validate = false;
                    }
                    else if (horaModalFinish > horaAtual) {
                        this.validation.modalFinish_operation_time.msgError =
                            this.$t('ProductionProgress.InvalidHour2');
                        this.validation.modalFinish_operation_time.isError = true;
                        validate = false;
                    }
                    else {
                        this.validation.modalFinish_operation_time.msgError = "";
                        this.validation.modalFinish_operation_time.isError = false;
                    }
                }
                // Hora maior do que o dia atual
                else if (
                    this.modalFinish.operation_date <= dataAtualFormatada
                ){
                    if(horaModalFinish > horaAtual){
                        this.validation.modalFinish_operation_time.msgError =
                        this.$t('ProductionProgress.InvalidHour2');
                        this.validation.modalFinish_operation_time.isError = true;
                        validate = false;
                    } else {
                        this.validation.modalFinish_operation_time.msgError = "";
                        this.validation.modalFinish_operation_time.isError = false;
                    }
                }
                else {
                    this.validation.modalFinish_operation_time.msgError = "";
                    this.validation.modalFinish_operation_time.isError = false;
                }
            }

            return validate;
        },
        onsubmit(
            status,
            typeModal,
            data,
            hora,
            produced_quantity,
            refuse,
            rework,
            rejection_reason_id,
            resource_id
        ) {
            let event_time = data + " " + hora;
            if (this.validationSubmit(typeModal)) {
                if (this.form_refuse > 0 && this.rejection_reason_id == 0)
                    return this.showToast(
                        "Error!",
                        "FrownIcon",
                        "The field Rejection Reason is required",
                        "danger"
                    );

                if (status == null)
                    return this.showToast(
                        "Error!",
                        "FrownIcon",
                        "The field status is required",
                        "danger"
                    );

                this.sendingForm = true;

                this.createNewOperationProgress(
                    status,
                    event_time,
                    produced_quantity,
                    refuse,
                    rework,
                    rejection_reason_id,
                    resource_id
                );
            }
        },
        formatDateTime(dateTime) {
            if (dateTime) {
                return moment(dateTime).format("YYYY-MM-DD HH:mm");
            }
        },
        formatDate(dateTime) {
            if (dateTime) {
                return moment(dateTime).format("YYYY-MM-DD");
            }
            return;
        },
        formatTime(dateTime) {
            if (dateTime) {
                return moment(dateTime).format("HH:mm");
            }
            return;
        },
        formatHour(hour) {
            let valorInteiro = Math.trunc(parseFloat(hour));
            let minutos = parseInt((parseFloat(hour) - valorInteiro) * 60);
            return valorInteiro + " h " + minutos + " m";
        },
        formatTotalApontado(dateTime) {
            if (dateTime) {
                const [hora, minuto] = moment(dateTime)
                    .format("HH:mm")
                    .split(":");
                return `${parseInt(hora)} h ${minuto} m`;
            }
            return;
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: icon,
                    text: text,
                    variant,
                },
            });
        },
        showSwal(title, icon, text) {
            return this.$swal({
                title: title,
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonText: "Yes!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-good-table.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";


@media (max-width: 768px) {
    .mobile {
        display: block;
        margin-bottom: 24px;
    }
    .web {
        display: none;
    }
    .col-indicador {
        transform: translate(-50%, -50%) !important;
    }
    .col-indicador-small{
        transform: translate(-50%, -100%) !important;
    }
    .graph-buttons-mobile {
        width: calc(100% + 32px);
        display: flex;
        justify-content: space-between;
        margin-bottom: 13px;
        .btn-graph-mobile {
            font-size: 14px;
            border: none;
        }
        .btn-active {
            border-bottom: 2px solid #974900;
            font-weight: 600;
            width: 100%;
            background-color: transparent !important;
            color: #974900 !important;
            border-radius: 0;
        }
        .btn-disabled {
            border-bottom: 2px solid #cfc4be;
            width: 100%;
            background-color: transparent !important;
            color: #cfc4be !important;
            border-radius: 0;
        }
    }
}
@media (min-width: 768px) {
    .mobile {
        display: none;
    }
    .web {
        display: block;
    }
}


.info-cards-orders-pontamento{
    .detail-orders{
        margin-bottom: 12px !important;
    }
    .col-order-name{
        margin-bottom: 11px !important;
        .order-name{
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #4C4541;
        }
    }

    .col-percent{
        .percent{
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #4C4541;
        }
    }
    .text-label{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #998F8A;
    }
    .info-order{
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: #4C4541;

    }
    .progress {
        background-color: #F7F3EF !important;
    }
}

#modal-setup,
#modal-running,
#modal-stop,
#modal-finish{
    padding-right: 0 !important;
    .modal-dialog {
        width:  95%;
        max-width: 746px;
        .modal-content {
            margin: 30px auto;
            width:  95%;
            border-radius: 6px;
            .modal-header {
                padding: 20px 16px !important;
                background-color: #eceff1;
                .modal-title {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    color: #4c4541;
                }
                .close {
                    color: #4c4541 !important;
                    transform: translate(3px, -16px) !important;
                }
            }
            .modal-body {
                padding: 20px 16px !important;

                .alert-fields {
                    margin-bottom: 20px;
                    span {
                        background-color: #f44336;
                        border-radius: 6px ;
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #FFFFFF;
                        text-align: left;
                        padding: 16px;
                    }
                }
                .card-error {
                    margin-bottom: 20px !important;
                    padding: 16px;
                    margin: 0 auto;
                    max-width: 96% !important;
                    background-color: #f44336;
                    border-radius: 6px;
                    .text-error {
                        text-align: left;
                        margin: 0;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #ffff;
                    }
                }
                .col-input-icon {
                    margin-top: 16px;
                    .form-control {
                        padding: 9px 14px 9px 42px;
                        &:disabled {
                            background-color: #eeeeee !important;
                            border: 1px solid #7e7570 !important;
                            color: #7e7570 !important;
                        }
                    }
                    .form-control[readonly] {
                        background-color: #ffff;
                    }
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 14px;
                        transform: translateY(-50%);
                    }
                }
                .col-input-form {
                    margin-top: 16px;
                }
                .col-select {
                    margin-top: 16px;
                }
                .col-button {
                    margin-top: 20px;
                    display: flex;
                    justify-content: end;
                    .form-group {
                        margin: 0;
                    }
                    .btn-color {
                        background-color: #974900 !important;
                        border-radius: 5px;
                        border: none;
                        padding: 13px 28px;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 20px;

                        &:hover {
                            background-color: #9F5714 !important;
                            box-shadow: none !important;
                        }
                    }
                }
                .form-item,
                .form-date {
                    .msgError {
                        margin: 0;
                        font-weight: 300;
                        font-size: 10px;
                        line-height: 14px;
                        color: #d32f2f;
                        text-align: left;
                    }
                    .form-group {
                        position: relative;
                        margin-bottom: 0;
                        .flatpickr-wrapper {
                            width: 100% !important;
                        }
                        .custom-select, .form-control {
                            &:disabled {
                                background-color: #eeeeee !important;
                                border: 1px solid #7e7570 !important;
                                color: #7e7570 !important;
                                opacity: 1;
                            }
                        }
                        .custom-select{
                            background: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.47162 2.05602L5.00158 6.5909L0.528469 2.05593L1.17644 1.40774L4.73416 5.02906L5.00178 5.30146L5.26928 5.02894L8.82368 1.40785L9.47162 2.05602Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.75'/%3E%3C/svg%3E")
                            right 1rem center/10px 10px no-repeat;
                        }
                        .col-form-label,
                        label {
                            padding-bottom: 4px;
                            display: flex !important;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            color: #4c4541;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

.container-prod-progress {
    margin-top: -1.5rem;

    .content-header-left {
        margin-bottom: 0 !important;
        .col-sm-4 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        .col-sm-8 {
            flex: 0 0 33.3333333333% !important;
            max-width: 33.3333333333% !important;
        }
    }
    .breadcrumb-slash {
        padding-left: 0;
    }
    .breadcrumb-wrapper {
        margin-bottom: 0 !important;
    }
}

.dark-layout {
    div ::v-deep {
        .b-overlay {
            .bg-light {
                background-color: $theme-dark-body-bg !important;
            }
        }
    }
}

.row-name-order {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.col-name-order {
    border-radius: 6px;
    margin-bottom: 16px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0;
    text-align: center;
    flex-direction: column;
    box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
    overflow: hidden;
    .container-apontamentos {
        height: auto;
        box-sizing: border-box;
        padding: 0 0 10px;
        width: 100%;
        background-color: #fff;

        .container-apontamentos-helper {
            &::-webkit-scrollbar {
                display: none;
            }
            .card-apontamento {
                box-sizing: border-box;
                padding: 2px 0;
                background-color: #ECEFF1;
                margin: 8px;
                border-radius: 4px;
                white-space: nowrap;
                span {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    color: #263238;
                    padding: 0 9px;
                }
            }
        }
    }
    h1.order-name,
    h2.order-status {
        width: 100%;
        margin: 0;
    }
    h1.order-name {
        padding: 15px 0 10px 0;
        color: #4c4541;
        background: #fff;
        font: 600 36px/50px "Roboto", sans-serif;
    }
    h2.order-status {
        padding: 8px 0 7px 0;
        color: #fff;
        font: 600 16px/26px "Roboto", sans-serif;
    }
}

.col-progress {
    display: flex;
    margin-bottom: 16px;
    .legenda {
        margin-top: 13px;
        display: flex;
        color: #998F8A;
        justify-content: center;
        .circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 3px;
        }
        .item-legenda {
            display: flex;
            align-items: center;
            justify-content: center;
            .dados-legenda {
                font: 600 12px/16px initial;
                margin-bottom: 0;
            }
        }
        .legenda-realizado,
        .legenda-produtivo {
            margin-right: 20px;
            .circle {
                background-color: #43a047 !important;
            }
        }
        .legenda-excedente {
            .circle {
                background-color: #e53935 !important;
            }
        }
    }
    .card-body {
        padding: 16px;
        .mobile-graph-area {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
    }
    .card-indicators {
        margin-bottom: 0;
        .row-indicators {
            margin-bottom: -16px;
            .title-chart {
                text-align: left;
                margin-bottom: 8px !important;
            }

            .dados {
                margin-bottom: 16px;
                .sub-title-chart {
                    margin-bottom: 0 !important;
                }
                .item-dado {
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 30px;
                    color: #4c4541;
                }
            }
        }
    }

    .title-icon {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 8px;
        svg {
            position: absolute;
            right: 16px;
            top: 16px;
        }
    }
    .title-chart {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #4c4541;
        margin-bottom: 0 !important;
    }
    .sub-title-chart {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #998f8a;
    }
    .card-chart {
        position: relative;
        height: 100%;
        margin-bottom: 0;
        .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
        .col-indicador-small{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -100%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .indicador-chart {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #998f8a;
                margin-bottom: 0;
            }
            .value-indicador-chart {
                font-weight: 600;
                font-size: 22px;
                line-height: 30px;
                color: #4c4541;
                margin-bottom: 0;
            }
        }
        .col-indicador {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .indicador-chart {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #998f8a;
                margin-bottom: 0;
            }
            .value-indicador-chart {
                font-weight: 600;
                font-size: 22px;
                line-height: 30px;
                color: #4c4541;
                margin-bottom: 0;
            }
        }
    }
    &.quantidade {
        .row-cards-progress {
            display: flex;
            width: 100%;
            .chart {
                display: flex;
                flex-direction: column;
                padding: 0;
                .dc-chart {
                    position: relative;
                    display: flex;
                    justify-content: center;
                }
                .col-indicador {
                    top: 36%;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    .value-indicador-chart {
                        font-size: 26px !important;
                        line-height: 36px !important;
                    }
                }
                .legend-chart {
                    text-align: center;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #998f8a;
                    margin-top: 12px;
                    margin-bottom: 7px !important;
                }
            }
        }
        .chart-bar-status {
            display: flex;
            margin-bottom: 36px;
            width:100%;
            :first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            :last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
            .bar {
                height: 10px;
            }
        }
        .card-chart.status {
            .card-body {
                padding: 16px 16px 36px 16px;
                .legend-status {
                    width: 100%;
                    .line {
                        width: 100%;
                        height: 1px;
                        background: #f2f0f4;
                        margin: 12px auto;
                    }
                    .legend {
                        position: relative;
                        display: flex;
                        justify-content: left;

                        .color-bar {
                            position: absolute;
                            height: 100%;
                            width: 5px;
                            border-radius: 40px;
                            left: 0;
                        }
                        .info-legend {
                            margin-left: 9px;
                            display: flex;
                            flex-direction: column;
                            .title {
                                font-weight: 300;
                                font-size: 12px;
                                line-height: 16px;
                                color: #998f8a;
                            }
                            .indicator {
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 16px;
                                color: #4c4541;
                            }
                        }
                    }
                }
            }
        }
    }
}

.col-btn-infos::-webkit-scrollbar{
    width: 0 !important;
}

.col-btn-infos {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    overflow-x: auto;
    flex-wrap: nowrap;

    .btn-infos {
        margin-right: 16px;
        background-color: transparent !important;
        border: none;
        border-radius: 6px;
        padding: 10px 16px;
        svg {
            margin-right: 10px;
            path {
                fill: #998f8a !important;
            }
        }
        span {
            color: #974900;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }

        &:focus,
        &.active {
            background-color: #974900 !important;
            span {
                color: #ffff !important;
            }
            svg {
                path {
                    fill: #ffff !important;
                }
            }
        }
    }
}

.multi-min-height {
    min-height: 205.94px;
}
.info-tooltip-link{
    padding: 2px 5px;
}


.info-tooltip-link{
    padding: 2px 5px;
}

.info-apontamento {
    .card-title-operation {
        margin-bottom: 16px !important;
        span {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #4C4541;
        }
    }
    &.links{
        .card-files-links{
            border: 1px solid #CFC4BE !important;
            border-radius: 6px;
            padding: 24px 16px;
            margin-bottom: 16px !important;
            display: flex;
            flex-direction: row;
            align-items: center;

            svg{
                margin-right: 18px ;
            }

            &:hover{
                cursor: pointer;
            }
            span{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
            }
        }
    }
    .info {
        margin-bottom: 16px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .sub-title-info {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998f8a;
            margin-bottom: 0 !important;
            justify-self: start;
        }
        .item-dado {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: #4c4541;
        }
    }
}
.table-apontamentos {
    .icon-trash {
        cursor: pointer;
        svg {
            path {
                fill: #974900 !important;
            }
        }
        &:hover {
            opacity: 0.7;
            transition: 0.2s;
        }
    }

}
.table-responsive{
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px !important;               /* width of the entire scrollbar */
        border-radius: 50%;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #FBEEE8;

      }
      &::-webkit-scrollbar-thumb {
        background-color: #CFC4BE;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        border: 10px solid transparent
      }
}
.row-cards {
    margin-bottom: 24px;
}

.dc-chart .pie-slice {
    cursor: default !important;
}

.tooltip-production-progress-op {
//não adicione padding aqui e só estilize as classes especificas se possível
  margin: 0;
  .tooltip-inner {
    padding: 0;
    max-width: 100%;
    min-width: 1%;
    border-radius: 6px;
    overflow: hidden;
    .title-tooltip-production-progress-op {
        display: flex;
        justify-content: left;
        background-color: #ff8d2e;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding: 7.5px 16px;
        border: 1px solid #cfc4be;
        border-top: none;
        max-width: 477px;
    }
    .info-tooltip-production-progress-op {
        text-align: left;
        background-color: #ffff;
        color: #4c4541;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 16px;
        border: 1px solid #cfc4be;
        border-top: none;
        max-width: 477px;
    }
  }

  .arrow {
    &::before {
      border-top-color: #cfc4be;
      border-bottom-color: #cfc4be;
    }
  }
}

@media(max-width: 480px){
    #modal-setup,
    #modal-running,
    #modal-stop,
    #modal-finish{
        .modal-dialog {
            max-width: 95% !important;

            .modal-content {
                max-width:  95% !important;
            }
        }
    }
    .row-name-order {
        .order-name {
            font-size: 22px !important;
            padding-top: 0 !important;
        }

        .order-status {
            font-size: 12px !important;
            height: 32px !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .container-apontamentos-helper {
            .card-apontamento {
                height: 18px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 4px !important;
                span {
                    font-size: 12px !important;
                }
            }
    }
    .row-cards {
        .title-card {
            margin-top: 8px !important;
            font-size: 18px !important;
        }

        .description-action {
            font-size: 10px !important;
        }

        .icon-card {
            scale: 0.8 !important;
        }

        .action {
            margin-bottom: 8px !important;
        }
    }
    .info-cards-orders-pontamento {
        .info-order {
            font-size: 14px !important;
            line-height: 20px !important;
        }
        .text-label {
            font-size: 12px !important;
            line-height: 16px !important;
        }

        .percent {
            font-size: 14px !important;
            line-height: 20px !important;
        }
    }
    .item-dado {
        font-size: 14px !important;
    }
    .sub-title-info{
        font-size: 12px !important;
    }
    .sub-title-chart{
        font-size: 12px !important;
    }
    .info-apontamento{
        .info{
            margin-bottom: 2px;
        }
    }
    .dados{
        margin-bottom: 2px !important;
    }
    .title-tooltip-production-progress-op,
    .info-tooltip-production-progress-op {
        max-width: 288px !important;
    }
}
@media(max-width: 767px){
    .col-indicador {
        transform: translate(-50%, -80%) !important;
    }

    .dados{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title-tooltip-production-progress-op,
    .info-tooltip-production-progress-op {
        max-width: 288px !important;
    }
}
</style>
