<template>
    <div :id="id"></div>
</template>

<script>
import crossfilter from "crossfilter2";
import * as d3 from "d3";
import * as dc from "dc";
export default {
    props: [
        "id",
        "order",
        "listProgress",
        "type",
    ],
    mounted() {
        this.generateChart();
    },
    methods: {
        generateChart() {
            var pieChartGenerated = new dc.PieChart('#'+this.id);
            var ndxGenerated = crossfilter(this.listProgress);
            var statusDimensionGenerated = ndxGenerated.dimension((d) => {
                return [d.title, d.percent];
            });
            var totalTimeDimensionGenerated = statusDimensionGenerated
                .group()
                .reduceSum((d) => parseFloat(d.percent) * 100);

            if(this.type == 1) {

                pieChartGenerated
                    .height(219)
                    .slicesCap(2)
                    .innerRadius(75)
                    .dimension(statusDimensionGenerated)
                    .group(totalTimeDimensionGenerated)
                    .label((d) => {
                        return `${(parseFloat(d.key[1]) * 100).toFixed(0)}%`;
                    })
                    .title((d) => {
                        return `${d.key[0]}: ${d.key[1] * 100}%`;
                    })
                    .colorAccessor((d) => d.key[0])
                    .colors(
                        d3
                            .scaleOrdinal()
                            .domain(["Produzido", "Excendente", "Faltante"])
                            .range(["#43A047", "#E53935", "#F4EEE8"])
                    )
                    .on("pretransition", (chart) => {
                        chart
                            .selectAll("g.deselected")
                            .classed("deselected", false);
                        chart.selectAll("g.selected").classed("selected", false);
                    });
            } else {
                pieChartGenerated
                    .height(116)
                    .width(116)
                    .slicesCap(2)
                    .innerRadius(50)
                    .dimension(statusDimensionGenerated)
                    .group(totalTimeDimensionGenerated)
                    .label((d) => {
                        return " ";
                    })
                    .title((d) => {
                        return `${d.key[0]}: ${d.key[1] * 100}%`;
                    })
                    .colorAccessor((d) => d.key[0])
                    .colors(
                        d3
                            .scaleOrdinal()
                            .domain(["Produzido", "Faltante"])
                            .range(["#FF8D2E", "#F4EEE8"])
                    )
                    .on("pretransition", (chart) => {
                        chart
                            .selectAll("g.deselected")
                            .classed("deselected", false);
                        chart.selectAll("g.selected").classed("selected", false);
                    });
        
        
            }
            pieChartGenerated.render();
        }

    },
};
</script>
