<template>
    <div>
        <b-form-group
        :label="label"
        >
            <b-form-select
                v-model="valueLocal"
                :value="value"
                :options="Options"
                :disabled="disabled"
                :class="[classe,
                isError && 'error']"
                :text="placeholder"
                @input="updateSelectValue(valueLocal)"
                :style="value == '' ? 'color:#CFC4BE;' : ''"
            />
        </b-form-group>
        <p class="msgError" v-if="isError">{{msgError}}</p>
    </div>
</template>

<script>
import {BFormSelect, BFormGroup} from 'bootstrap-vue'
export default {
   components: {
        BFormSelect,
        BFormGroup
    },
    props: {
        Options: {
            type: Array,
            default: [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        classe: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        value: {
            default: '',
        },
        isError: {
            type: Boolean,
            default: false,
        },
        msgError: {
            type: String,
            default: '',
        },
    },
    data(){
        return{
            valueLocal: ''
        }
    },
    mounted() {
        if (this.value){
            this.valueLocal = this.value
        }
    },
    methods: {
    updateSelectValue: function (value) {
        this.$emit("input", value);
    },
    
  },
}
</script>
<style>
.custom-select.error{
    border: 1px solid #D32F2F !important;
}

.custom-select option:not(:checked){
        color: #4C4541;
}
</style>

