<template>
    <div>
        <b-form-group
        :label="label"
        >
            <b-form-input
                :min="min" 
                :step="step"
                :oninput="oninput"
                v-model="valueLocal"
                :placeholder="placeholder"
                :type="type"
                :class="[classe,
                isError && 'error']"
                :disabled="disabled"
                @input="updateSelectValue(valueLocal)"
                
            />
        </b-form-group>
        <p class="msgError" v-if="isError">{{msgError}}</p>
    </div>
</template>

<script>
import {BFormInput, BFormGroup} from 'bootstrap-vue'
export default {
   components: {
        BFormInput,
        BFormGroup
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        classe: {
            type: String,
            default: '',
        },
        value: {
            default: '',
        },
        isError: {
            type: Boolean,
            default: false,
        },
        msgError: {
            type: String,
            default: '',
        },
        min: {
            type: String,
            default: ''
        },
        step: {
            type: String,
            default: ''
        },
        oninput: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            valueLocal: ''
        }
    },
    mounted() {
        if (this.value){
            this.valueLocal = this.value
        }
    },
    methods: {
    updateSelectValue: function (value) {
        this.$emit("input", value);
    },
    
  },
}
</script>
<style>
.form-control.error{
    border: 1px solid #D32F2F !important;
}
</style>

